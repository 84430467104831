import React, { useState, useEffect } from 'react';
import { ProgressIndicator, ProgressStep } from 'carbon-components-react';
import { __trans, makeRequest, userDetails } from '../../../_helpers';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';

//import { callCommonAction } from '../../../redux/Common/CommonReducer';
import useToastAlert from '../../../hooks/useToastAlert';
import ToastNotification from '../../common/ToastNotification';
import ContractSignUp from './setup-partials/ContractSignUp';
import QuestionAnswer from './setup-partials/QuestionAnswer';
import UploadDocument from './setup-partials/UploadDocument';
import ClientFeedback from './setup-partials/ClientFeedback';

const ClientAccountSetup = () => {
  const { addError } = useToastAlert();
  const { reloaddata } = useSelector((state) => state.common);
  //const dispatch = useDispatch();
  const [currentstep, setCurrentStep] = useState(null);
  const [requestdata, setRequestData] = useState({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');

  const getRequestData = async () => {
    let user_id = userDetails('id');
    await makeRequest('firm/get-request-by-client-id/' + user_id, {}, 'POST', addError).then(
      (res) => {
        //console.log('res get request data', res)
        if (res.type === 'success') {
          console.log(code, 'fdwqewe');
          setRequestData(res.data);
          if (!code) {
            setCurrentStep(0);
          } else {
            setCurrentStep(2);
          }
        }
      }
    );
  };

  useEffect(() => {
    getRequestData();
  }, []);

  useEffect(() => {
    getRequestData();
  }, [reloaddata]);

  const handleStepsChange = (next_step) => {
    setCurrentStep(next_step);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="d-flex justify-content-end">
          <ToastNotification />
        </div>
        <div className="row align-items-center py-4 px-4">
          <div className="col-md-2">
            <img className="dark-bg-logo img-fluid" src="/images/logo.png" alt="" title="" />
          </div>
          <div className="col-md-8 cstm-progress">
            <ProgressIndicator
              currentIndex={currentstep}
              className="d-flex justify-content-center w-100"
            >
              <ProgressStep className="w-100" label={__trans('account_setup_step1_info')} />
              <ProgressStep className="w-100" label={__trans('account_setup_step2_info')} />
              <ProgressStep className="w-100" label={__trans('account_setup_step3_info')} />
              <ProgressStep
                className="w-100"
                label={__trans('account_setup_step4_info')}
                secondaryLabel={__trans('account_setup_feedback_optional')}
              />
            </ProgressIndicator>
          </div>
          <div className="col-md-2"></div>
        </div>

        {/* Step 1 START  */}
        {currentstep === 0 ? (
          <ContractSignUp
            currentstep={currentstep}
            handleNextPrevoius={handleStepsChange}
            requestdata={requestdata}
          />
        ) : (
          ''
        )}
        {/* Step 2 START  */}
        {currentstep === 1 ? (
          <QuestionAnswer
            currentstep={currentstep}
            handleNextPrevoius={handleStepsChange}
            requestdata={requestdata}
          />
        ) : (
          ''
        )}
        {/* Step 3 START  */}
        {currentstep === 2 ? (
          <UploadDocument
            currentstep={currentstep}
            handleNextPrevoius={handleStepsChange}
            requestdata={requestdata}
          />
        ) : (
          ''
        )}
        {/* Step 4 START  */}
        {currentstep === 3 ? (
          <ClientFeedback
            currentstep={currentstep}
            handleNextPrevoius={handleStepsChange}
            requestdata={requestdata}
            setRequestData={setRequestData}
          />
        ) : (
          ''
        )}

        <div className="row px-3 py-2 frontend-footer">
          <div className="col text-end">
            <span className="me-3">Copyright © 2024</span>{' '}
            <Link to="/term-and-conditions" target={'_blank'}>
              {__trans('terms_of_use_link')}
            </Link>{' '}
            -{' '}
            <Link to="/privacy-policy" target={'_blank'}>
              {__trans('privacy_policy_link')}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientAccountSetup;
