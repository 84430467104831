import React, { useState } from 'react';
import { Grid, Column, Modal, TextInput } from '@carbon/react';
import useToastAlert from '../../../../hooks/useToastAlert';
import helpers from '../../../../_helpers/common';
import { __trans, makeRequest } from '../../../../_helpers';

const InviteFirm = ({ open, setOpen }) => {
  const { addError } = useToastAlert();
  const [submitcontrol, setSubmitControle] = useState({
    status: 'inactive',
    description: __trans('submit_button'),
  });
  const [form, setForm] = useState({
    email: '',
    email_error: '',
    first_name: '',
    first_name_error: '',
    last_name: '',
    last_name_error: '',
  });

  const resetSubmitControlStatus = () => {
    setSubmitControle({
      ...submitcontrol,
      status: 'inactive',
      description: __trans('submit_button'),
    });
  };

  const valueChanged = (e) => {
    const { name, value } = e.target;
    let newEdit = { ...form };
    newEdit[name] = value;
    newEdit[name + '_error'] = '';
    setForm(newEdit);
  };

  const validateForm = () => {
    let newEdit = { ...form };
    let validateData = true;

    if (helpers.isEmpty(form.email)) {
      newEdit.email_error = __trans('error_msg.email_error');
      validateData = false;
    } else {
      newEdit.email_error = '';
      if (!helpers.isValidEmail(form.email)) {
        newEdit.email_error = __trans('error_msg.email_invalid');
        validateData = false;
      }
    }

    if (helpers.isEmpty(form.first_name)) {
      newEdit.first_name_error = __trans('error_msg.first_name_error');
      validateData = false;
    } else {
      newEdit.first_name_error = '';
    }

    if (helpers.isEmpty(form.last_name)) {
      newEdit.last_name_error = __trans('error_msg.last_name_error');
      validateData = false;
    } else {
      newEdit.last_name_error = '';
    }
    setForm(newEdit);
    return validateData;
  };

  const handleSubmit = (e) => {
    //console.log('handle submit clicked');
    //Before submit validate form
    if (validateForm()) {
      submitForm({ email: form.email, first_name: form.first_name, last_name: form.last_name });
    }
  };

  const submitForm = async (formData) => {
    // console.log('formData',formData);
    // return false;
    setSubmitControle({ ...submitcontrol, status: 'active' });
    await makeRequest('users/send-firm-invitation-email', formData, 'POST', addError)
      .then((res) => {
        setSubmitControle({ ...submitcontrol, status: 'finished' });
        if (res.type === 'success') {
          setForm({
            email: '',
            email_error: '',
            first_name: '',
            first_name_error: '',
            last_name: '',
            last_name_error: '',
          });
          //setTimeout(() => {
          setOpen(false);
          //}, 2000);
        }
      })
      .catch((error) => {
        setSubmitControle({ ...submitcontrol, status: 'finished' });
      });
  };

  const handleCancel = () => {
    setForm({
      email: '',
      email_error: '',
      first_name: '',
      first_name_error: '',
      last_name: '',
      last_name_error: '',
    });
    setOpen(false);
  };

  return (
    <Modal
      className="header-border"
      modalHeading={__trans('invite_firm.heading')}
      modalLabel=""
      preventCloseOnClickOutside={true}
      open={open}
      onRequestClose={() => handleCancel()}
      primaryButtonText={__trans('invite_firm.invite_button')}
      secondaryButtonText={__trans('cancel_button')}
      onRequestSubmit={() => handleSubmit()}
      loadingStatus={submitcontrol.status}
      loadingDescription={submitcontrol.description}
      onLoadingSuccess={resetSubmitControlStatus}
    >
      <div className="">
        <form className="mb-3">
          <Grid fullWidth condensed className="px-0">
            <Column sm={16} md={16} lg={16}>
              <div className="row">
                <div className="col-6">
                  <TextInput
                    type="text"
                    name="first_name"
                    id="first_name"
                    value={form.first_name}
                    onChange={valueChanged}
                    labelText={__trans('invite_firm.first_name')}
                    placeholder={__trans('invite_firm.first_name_placeholder')}
                    invalid={form.first_name_error !== '' ? true : false}
                    invalidText={form.first_name_error}
                  />
                </div>
                <div className="col-6">
                  <TextInput
                    type="text"
                    name="last_name"
                    id="last_name"
                    value={form.last_name}
                    onChange={valueChanged}
                    labelText={__trans('invite_firm.last_name')}
                    placeholder={__trans('invite_firm.last_name_placeholder')}
                    invalid={form.last_name_error !== '' ? true : false}
                    invalidText={form.last_name_error}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <TextInput
                    type="email"
                    name="email"
                    id="email"
                    value={form.email}
                    onChange={valueChanged}
                    labelText={__trans('invite_firm.email_label')}
                    placeholder={__trans('invite_firm.email_placeholder')}
                    invalid={form.email_error !== '' ? true : false}
                    invalidText={form.email_error}
                  />
                </div>
              </div>
            </Column>
          </Grid>
        </form>
      </div>
    </Modal>
  );
};

export default InviteFirm;
