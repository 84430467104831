import React, { useState, useEffect } from 'react';
import { Form, Stack, TextInput, Select, SelectItem, Loading } from '@carbon/react';
import { useSelector, useDispatch } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { callCommonAction } from '../../../../redux/Common/CommonReducer';
import useToastAlert from '../../../../hooks/useToastAlert';
import { __trans, makeRequest } from '../../../../_helpers';
import { GetCountries } from '../../../../_helpers/select-list';

const SelectClient = ({ request, show }) => {
  //console.log('request', request)
  const { addError } = useToastAlert();
  const { contentLoading } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const [client, setClient] = useState({
    client_id: '',
    first_name: '',
    last_name: '',
    email: '',
    company_name: '',
    cuil_cuit: '',
    cuil_cuit_number: '',
    phone_country_code: '',
    phone_no: '',
    extension: '',
  });
  const [countries, setCountries] = useState([]);

  const getUserData = async () => {
    try {
      dispatch(callCommonAction({ contentLoading: true }));
      const res = await makeRequest(`users/detail/${request.client_id}`, 'GET');
      dispatch(callCommonAction({ contentLoading: false }));
      if (res.type === 'success') {
        setClient(res.data);
      }
    } catch (error) {
      dispatch(callCommonAction({ contentLoading: false }));
      addError(error.message, 'error');
    }
  };

  const getCountryData = async () => {
    await GetCountries().then((data) => {
      setCountries(data);
    });
  };

  useEffect(() => {
    getCountryData();
    if (show === 1) {
      if (request?.client_id !== undefined) {
        getUserData();
      }
    }
  }, [show]);

  //console.log('client data', client)
  return (
    <div className="bg-body">
      {!contentLoading ? (
        <Form aria-label="create/update client form" className="border p-3">
          <Stack>
            <div className="">
              <div className="row mb-3">
                {request?.client_request_id !== '' ? (
                  <div className="col-md-2 ">
                    <TextInput
                      type="text"
                      name="client_id"
                      id="client_id"
                      readOnly={true}
                      value={client.client_id}
                      labelText={__trans('forms.client_id_label')}
                    />
                  </div>
                ) : (
                  ''
                )}
                <div className="col-md-5 ">
                  <TextInput
                    type="text"
                    name="first_name"
                    id="first_name"
                    readOnly={true}
                    value={client.first_name}
                    labelText={__trans('forms.first_name')}
                    placeholder={__trans('forms.first_name_placeholder')}
                  />
                </div>
                <div className="col-md-5 ">
                  <TextInput
                    type="text"
                    name="last_name"
                    id="last_name"
                    readOnly={true}
                    value={client.last_name}
                    labelText={__trans('forms.last_name')}
                    placeholder={__trans('forms.last_name_placeholder')}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-5 ">
                  <TextInput
                    type="text"
                    name="company_name"
                    id="company_name"
                    readOnly={true}
                    value={client?.client_profile_id ? client?.client_profile_id?.company_name : ''}
                    labelText={__trans('forms.company_name_label')}
                    placeholder={__trans('forms.company_name_placeholder')}
                  />
                </div>
                <div className="col-md-7 ">
                  <TextInput
                    type="email"
                    name="email"
                    id="firm_email"
                    readOnly={true}
                    value={client.email}
                    labelText={__trans('forms.email_label')}
                    placeholder={__trans('forms.email_placeholder')}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-2 ">
                  <Select
                    name="cuil_cuit"
                    id="cuil_cuit"
                    readOnly={true}
                    value={client?.client_profile_id ? client?.client_profile_id?.cuil_cuit : ''}
                    labelText={__trans('forms.cuil_cuit_label')}
                    placeholder={__trans('forms.cuil_cuit_placeholder')}
                  >
                    <SelectItem value="" text="Select" />
                    <SelectItem value="CUIL" text="CUIL" />
                    <SelectItem value="CUIT" text="CUIT" />
                  </Select>
                </div>
                <div className="col-md-3 ">
                  <TextInput
                    type="text"
                    name="cuil_cuit_number"
                    id="cuil_cuit_number"
                    readOnly={true}
                    value={
                      client?.client_profile_id ? client?.client_profile_id?.cuil_cuit_number : ''
                    }
                    labelText={__trans('forms.cuil_cuit_number_label')}
                    placeholder={__trans('forms.cuil_cuit_number_placeholder')}
                  />
                </div>
                <div className="col-md-5 px-1">
                  <div className="cds--form-item cds--text-input-wrapper">
                    <div className="cds--text-input__label-wrapper">
                      <label labelfor="phone_no" className="cds--label" dir="auto">
                        {__trans('forms.phone_no_label')}
                      </label>
                    </div>
                    <div className="cds--text-input__field-outer-wrapper">
                      <div className="cds--text-input__field-wrapper" data-invalid="true">
                        <PhoneInput
                          className=""
                          country={'us'}
                          enableSearch={true}
                          disableSearchIcon={true}
                          value={client.phone_no}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 ">
                  <TextInput
                    type="text"
                    name="extension"
                    id="extension"
                    readOnly={true}
                    value={client.extension}
                    labelText={__trans('forms.extension_label')}
                    placeholder={__trans('forms.extension_placeholder')}
                  />
                </div>
              </div>
            </div>
            {request?.client_id_error !== '' ? (
              <span className="text-danger">{request?.client_id_error}</span>
            ) : (
              ''
            )}
          </Stack>
        </Form>
      ) : (
        <div className="d-flex justify-content-center">
          <Loading className="text-center" withOverlay={false} />
        </div>
      )}
    </div>
  );
};

export default SelectClient;
