import React, { useState, useEffect } from 'react';
import {
  Stack,
  Tag,
  Section,
  Heading,
  Modal,
  ContainedList,
  ContainedListItem,
  Loading,
} from '@carbon/react';
import {
  User,
  Email,
  Phone,
  CheckmarkFilled,
  WarningAltFilled,
  Ticket,
  CalendarAdd,
  Badge,
  UserMultiple,
} from '@carbon/icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import Moment from 'moment';
import 'moment/locale/es';
import DummyImage from '../../../assets/images/user-dummy.jpg';
import useToastAlert from '../../../hooks/useToastAlert';
import { __trans, makeRequest } from '../../../_helpers';
//import helpers from '../../../_helpers/common'
Moment.locale('es');

const ViewProfile = ({ open, setOpen, id }) => {
  const { addError } = useToastAlert();
  const { contentLoading } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const [profiledata, setProfileData] = useState({});

  useEffect(() => {
    if (open) {
      getUserData();
    }
  }, [open]);

  const getUserData = async () => {
    let user_data = JSON.parse(localStorage.user);
    try {
      var request_user_id = id === '0' ? user_data.id : id;
      dispatch(callCommonAction({ contentLoading: true }));
      const res = await makeRequest(`users/detail/${request_user_id}`, 'GET');
      dispatch(callCommonAction({ contentLoading: false }));
      if (res.type === 'success') {
        //console.log('users/detail', res.data)
        setProfileData(res.data);
      }
    } catch (error) {
      addError(error.message, 'error');
    }
  };

  const handleCancel = () => {
    setProfileData({});
    setOpen(false);
  };

  const getImage = () => {
    //console.log('userdata', userdata)
    if (profiledata && profiledata.user_image !== null) {
      return process.env.REACT_APP_ASSET_ENDPOINT + profiledata.user_image;
    } else {
      return DummyImage;
    }
  };

  const getUserStatus = () => {
    return (
      <Tag className="table-badge" type={profiledata.status === 1 ? 'green' : 'red'}>
        {profiledata.status === 1 ? 'Active' : 'In-Active'}
      </Tag>
    );
  };

  const checkHaveSubscription = () => {
    if (profiledata.role_id === 2) {
      if (profiledata.firm_id !== null) {
        if (profiledata.firm_id.plan_id !== null) {
          return true;
        }
        return false;
      }
      return false;
    }
    return false;
  };
  const getSubscriptionDetail = (colomn) => {
    if (profiledata.role_id === 2) {
      if (profiledata.firm_id !== null) {
        if (profiledata.firm_id.plan_id !== null) {
          if (colomn === 'name') {
            return profiledata.firm_id.plan_id?.name;
          } else if (colomn === 'client_limit') {
            return profiledata.firm_id.client_limit;
          }
        }
      }
    }
    return '';
  };

  return (
    <Modal
      className="header-border"
      open={open}
      modalHeading={__trans('view_profile_page_heading')}
      preventCloseOnClickOutside={true}
      onRequestClose={() => handleCancel()}
      passiveModal
    >
      {!contentLoading ? (
        <Stack gap={5}>
          {/*<div className="text-center">
				<img src={ getImage() } alt="User Image" className="border border-secondary me-2" />
				</div>*/}
          <ContainedList label="" kind="disclosed">
            <ContainedListItem renderIcon={User}>
              {__trans('profile_label_name')}: {profiledata ? profiledata.fullname : ''}
            </ContainedListItem>
            <ContainedListItem renderIcon={Email}>
              {__trans('profile_label_email')}: {profiledata ? profiledata.email : ''}
            </ContainedListItem>
            <ContainedListItem renderIcon={Phone}>
              {__trans('profile_label_phone_no')}: {profiledata ? profiledata.phone_no : ''}
            </ContainedListItem>
            <ContainedListItem
              renderIcon={profiledata.status === 1 ? CheckmarkFilled : WarningAltFilled}
            >
              {__trans('profile_label_status')}: {getUserStatus()}
            </ContainedListItem>
            <ContainedListItem renderIcon={CalendarAdd}>
              {__trans('profile_label_created_at')}:{' '}
              {Moment(profiledata.created_at).format('D MMM, YYYY')}
            </ContainedListItem>
            {profiledata.role_id === 3 ? (
              <ContainedListItem renderIcon={Ticket}>
                {__trans('profile_label_licence_no')}: {profiledata.licence_no}
              </ContainedListItem>
            ) : (
              ''
            )}
            {checkHaveSubscription() ? (
              <div className="bg-primary-subtle mb-3 p-3 rounded-1">
                <Section level={4}>
                  <Heading className="mb-1">{__trans('profile_label_subscription_detail')}</Heading>
                </Section>
                <ContainedListItem renderIcon={Badge}>
                  {__trans('profile_label_subscription_plan')}: {getSubscriptionDetail('name')}
                </ContainedListItem>
                <ContainedListItem renderIcon={UserMultiple}>
                  {__trans('profile_label_client_limit')}: {getSubscriptionDetail('client_limit')}
                </ContainedListItem>
              </div>
            ) : (
              ''
            )}
          </ContainedList>
        </Stack>
      ) : (
        <div className="d-flex justify-content-center">
          <Loading className="text-center" withOverlay={false} />
        </div>
      )}
    </Modal>
  );
};

export default ViewProfile;
