import React from 'react';
import { Modal } from 'carbon-components-react';
import { __trans } from '../../../_helpers';

const NoFIleNotification = ({ open, setOpen }) => {
  return (
    <Modal
      open={open}
      onRequestClose={() => setOpen(false)}
      preventCloseOnClickOutside={true}
      modalHeading={__trans('common.request_has_no_file')}
      modalLabel=""
      passiveModal={true}
      preventCloseOnClickOutside={true}
    />
  );
};

export default NoFIleNotification;
