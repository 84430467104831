import { Link, useNavigate } from 'react-router-dom';
import { Link as CarbonLink } from '@carbon/react';
import { Logout } from '@carbon/icons-react';
import ToastNotification from '../common/ToastNotification';
import { __trans, checkUserLoggedIn } from '../../_helpers';

const WebLayout = ({ children }) => {
  const navigate = useNavigate();
  function handleLogout() {
    localStorage.clear();
    navigate('/login');
  }
  return (
    <>
      <div className="py-3 top-header mb-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col logo">
              <img src="/images/logo.png" alt="Logo" />
            </div>
            <div className="col text-end">
              {checkUserLoggedIn() ? (
                <CarbonLink
                  className="fs-15 text-white"
                  href="#"
                  onClick={handleLogout}
                  renderIcon={Logout}
                >
                  {__trans('logout_link')}
                </CarbonLink>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="d-flex justify-content-end">
          <ToastNotification />
        </div>
        {children}
      </div>
      <div className="border border-bottom-0 border-top mb-3 mt-5 pt-4">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p>© 2024 My Company. All rights reserved.</p>
            </div>
            <div className="col-md-6 footer-links">
              <p className="text-md-end">
                <Link to="/contact-us" target={'_blank'}>
                  {__trans('contact_us_link')}
                </Link>
                <Link to="/term-and-conditions" target={'_blank'}>
                  {__trans('terms_of_use_link')}
                </Link>
                <Link to="/privacy-policy" target={'_blank'}>
                  {__trans('privacy_policy_link')}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebLayout;
