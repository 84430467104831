import { __trans } from '../../../_helpers';

export const Header = [
  {
    id: 'client_id',
    title: __trans('delivered_client_table_header.client_id'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'first_name',
    title: __trans('delivered_client_table_header.first_name'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'last_name',
    title: __trans('delivered_client_table_header.last_name'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'last_activity',
    title: __trans('delivered_client_table_header.activity'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'year',
    title: __trans('delivered_client_table_header.year'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'client_status',
    title: __trans('delivered_client_table_header.status'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'signatures',
    title: __trans('delivered_client_table_header.signatures'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'questions',
    title: __trans('delivered_client_table_header.questions'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'documents',
    title: __trans('delivered_client_table_header.documents'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'actions',
    title: __trans('delivered_client_table_header.actions'),
  },
];
