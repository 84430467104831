import React from 'react';
import { CheckboxGroup, SkeletonText, Search } from '@carbon/react';
import { Checkbox, Button, InlineLoading, Loading } from 'carbon-components-react';
import { TrashCan as Delete, Edit } from '@carbon/icons-react';
import helpers from '../../../../../_helpers/common';
import { __trans } from '../../../../../_helpers';

const QuestionList = ({
  optionrows,
  selectedoption,
  setOptionIsChecked,
  handleCreateUpdateQuestion,
  handleDeleteQuestion,
  selectoptionerror,
  searchQuestion,
}) => {
  //console.log('form', form)
  return (
    <>
      <div className="align-items-center border-bottom">
        <div className="row mb-3 align-items-center">
          <div className="col-6">
            <h6>{__trans('select_template_question')}</h6>
          </div>
          <div className="col-6">
            <Search
              size="sm"
              id="search-6"
              placeholder={__trans('forms.search_placeholder')}
              labelText={__trans('forms.search_client_label')}
              closeButtonLabelText={__trans('clear_search_button')}
              onChange={(e) => searchQuestion(e)}
            />
          </div>
        </div>
      </div>
      {optionrows.length > 0 ? (
        <div className="mt-2 border" style={{ height: '250px', overflowY: 'scroll' }}>
          <div className="col-12">
            <CheckboxGroup className="p-2" legendText="">
              {optionrows.map((row, index) => {
                if (!selectedoption.includes(row._id)) {
                  return (
                    <div
                      key={row._id}
                      className="align-items-center bg-body-secondary border d-flex px-3 py-1 mb-1 manage-questions"
                    >
                      <div className="">
                        <Checkbox
                          key={row._id}
                          name={'checkbox-' + index}
                          value={row._id}
                          id={'checkbox-' + index}
                          onChange={(e) => setOptionIsChecked(e)}
                          labelText={row.name}
                          checked={selectedoption.includes(row._id)}
                        />
                      </div>
                      <div className="actions flex-shrink-0 ms-auto ps-2">
                        {helpers.checkCanDeleteEditItem(row) ? (
                          <span>
                            <Button
                              size="sm"
                              kind="ghost"
                              renderIcon={Edit}
                              onClick={() => handleCreateUpdateQuestion(row._id)}
                              iconDescription={__trans('edit_button')}
                              hasIconOnly
                            />
                            <Button
                              size="sm"
                              kind="ghost"
                              renderIcon={Delete}
                              onClick={() => handleDeleteQuestion(row._id)}
                              iconDescription={__trans('delete_button')}
                              hasIconOnly
                            />
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  );
                }
              })}
              {selectoptionerror !== '' ? (
                <span className="text-danger">{selectoptionerror}</span>
              ) : (
                ''
              )}
            </CheckboxGroup>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};
export default QuestionList;
