import React, { useEffect, useState } from 'react';
import { Form, RadioButton, RadioButtonGroup, Button, InlineLoading } from '@carbon/react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import AuthLayout from '../../layout/AuthLayout';
import useToastAlert from '../../../hooks/useToastAlert';
import helpers from '../../../_helpers/common';
import { __trans, makeRequest } from '../../../_helpers';

const SendVerificationCode = () => {
  const { addError } = useToastAlert();
  const { loading } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form, setForm] = useState({ send_on: '', send_on_error: '' });

  useEffect(() => {
    //Check if user logged in otherwise redirect him to login page
    checkUserIsLoggedIn();
  });

  const checkUserIsLoggedIn = () => {
    if (!localStorage.getItem('user')) {
      navigate('/login');
    }
  };

  const getUserDetail = (key) => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      if (key === 'email') {
        return helpers.mungeEmailAddress(user.email);
      } else if (key === 'phone_no') {
        return helpers.mungePhoneNoAddress(user.phone_no);
      } else if (key === 'id') {
        return user._id;
      }
    }
  };

  const valueChanged = (value) => {
    dispatch(callCommonAction({ verification_otp_on: value }));
    setForm({ ...form, send_on: value, send_on_error: '' });
  };

  const validateForm = () => {
    let newEdit = { ...form };
    let validateData = true;
    if (form.send_on === '') {
      newEdit.send_on_error = __trans('error_msg.select_otp_send_option');
      validateData = false;
    } else {
      newEdit.send_on_error = '';
    }
    setForm(newEdit);
    return validateData;
  };

  const handleSubmit = (e) => {
    //Before submit validate form
    if (validateForm()) {
      submitForm({ send_on: form.send_on, user_id: getUserDetail('id') });
    }
  };

  const submitForm = async (formData) => {
    dispatch(callCommonAction({ loading: true }));
    await makeRequest('users/send-verification-otp', formData, 'POST', addError)
      .then((res) => {
        dispatch(callCommonAction({ loading: false, verification_otp_on: form.send_on }));
        if (res.type === 'success') {
          navigate('/verify-otp');
        }
      })
      .catch((error) => {
        dispatch(callCommonAction({ loading: false }));
      });
  };

  return (
    <AuthLayout>
      <Form className="signup-form mb-3">
        <div className="form-heading mb-5 pt-3">
          <h3 className="mb-3">{__trans('verification_page_heading')}</h3>
          <p>{__trans('verification_page_title')} </p>
        </div>
        <div className="mb-4">
          <RadioButtonGroup
            legendText=""
            name="send_on"
            onChange={valueChanged}
            orientation="vertical"
            invalid={form.send_on_error !== '' ? true : false}
            invalidText={form.send_on_error}
          >
            {/*<RadioButton className="mb-4" value="phone" id="radio-1" 
							labelText={<div>Text Message<br/><span className="text-info">{ getUserDetail('phone_no')}</span></div>}
					/>*/}
            <RadioButton
              value="email"
              id="radio-2"
              labelText={
                <div>
                  Email Address
                  <br />
                  <span className="text-info">{getUserDetail('email')}</span>
                </div>
              }
            />
          </RadioButtonGroup>
        </div>
        <div className="mt-4 mb-4">
          {!loading ? (
            <Button className="btn btn-primary" onClick={handleSubmit} size="md">
              {__trans('continue_button')}
            </Button>
          ) : (
            <Button kind="secondary" size="md" as="div" role="button">
              <InlineLoading
                className="text-info"
                style={{ marginLeft: '1rem' }}
                description="Wait..."
                status="active"
                aria-live="off"
              />
            </Button>
          )}
        </div>
        <div>
          <p>{__trans('info_message')}</p>
        </div>
        <div className="text-center mt-3">
          {__trans('need_help')}{' '}
          <Link
            to="/contact-us"
            target={'_blank'}
            className="text-decoration-none fs-14 text-primary"
          >
            {__trans('contact_us')}
          </Link>
        </div>
      </Form>
    </AuthLayout>
  );
};

export default SendVerificationCode;
