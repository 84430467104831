import React, { useState, useEffect } from 'react';
import { Modal, Form, Stack, TextInput, Select, SelectItem, Loading } from '@carbon/react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useSelector, useDispatch } from 'react-redux';
import { callCommonAction } from '../../../../redux/Common/CommonReducer';
import useToastAlert from '../../../../hooks/useToastAlert';
import helpers from '../../../../_helpers/common';
import { __trans, makeRequest, getData } from '../../../../_helpers';
import { GetCountries, GetState, GetCity } from '../../../../_helpers/select-list';

const CreateUpdateClient = ({ open, setOpen, id }) => {
  const { addError } = useToastAlert();
  const { contentLoading } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [submitcontrol, setSubmitControle] = useState({
    status: 'inactive',
    description: __trans('submit_button'),
  });
  const [form, setForm] = useState({
    client_id: '',
    first_name: '',
    last_name: '',
    email: '',
    company_name: '',
    cuil_cuit: '',
    cuil_cuit_number: '',
    phone_country_code: '',
    phone_no: '',
    extension: '',
    tax_address: '',
    address_line_1: '',
    city: 0,
    province: 0,
    country: 0,
    zip_code: '',
    status: 1,
  });
  const [errors, setErrors] = useState({
    first_name: '',
    last_name: '',
    email: '',
    company_name: '',
    cuil_cuit: '',
    cuil_cuit_number: '',
    phone_country_code: '',
    phone_no: '',
    extension: '',
    tax_address: '',
    address_line_1: '',
    city: '',
    province: '',
    country: '',
    zip_code: '',
    status: 1,
  });

  const resetSubmitControlStatus = () => {
    setSubmitControle({
      ...submitcontrol,
      status: 'inactive',
      description: __trans('submit_button'),
    });
  };

  const getFormData = async () => {
    if (id !== '0') {
      await getData('users/detail/' + id, {}, 'POST', addError, dispatch)
        .then((res) => {
          if (res.type === 'success') {
            setForm({
              ...form,
              client_id: res.data.client_id,
              first_name: res.data.first_name,
              last_name: res.data.last_name,
              email: res.data.email,
              phone_no: res.data.phone_no,
              status: res.data.status,
              company_name: res.data.client_profile_id.company_name,
              cuil_cuit: res.data.client_profile_id.cuil_cuit,
              cuil_cuit_number: res.data.client_profile_id.cuil_cuit_number,
              phone_country_code: res.data.client_profile_id.phone_country_code,
              extension: res.data.extension,
              tax_address: res.data.client_profile_id.address,
              address_line_1: res.data.client_profile_id.address_line_1,
              country: res.data.client_profile_id.country,
              province: res.data.client_profile_id.state,
              city: res.data.client_profile_id.city,
              zip_code: res.data.client_profile_id.zip_code,
            });
            //}
            getStateData(res.data.client_profile_id.country, 'load');
            getCityData(
              res.data.client_profile_id.country,
              res.data.client_profile_id.state,
              'load'
            );
          }
        })
        .catch((error) => {});
    }
  };

  const getCountryData = async () => {
    await GetCountries().then((data) => {
      setCountries(data);
    });
  };

  const getStateData = async (countryid, type) => {
    if (type === 'change') {
      setStates([]);
      setCities([]);
      setForm({ ...form, province: '', city: '' });
    }
    GetState(countryid).then((data) => {
      setStates(data);
    });
  };

  const getCityData = async (countryid, provinceid, type) => {
    if (type === 'change') {
      setCities([]);
      setForm({ ...form, city: '' });
    }
    GetCity(countryid, provinceid).then((data) => {
      setCities(data);
    });
  };

  useEffect(() => {
    if (open) {
      getFormData();
      getCountryData();
    }
  }, [open]);

  const valueChanged = (e) => {
    const { name, value } = e.target;
    if (name === 'country') {
      getStateData(value, 'change');
    } else if (name === 'province') {
      getCityData(form.country, value, 'change');
    }
    let newEdit = { ...form };
    newEdit[name] = value;
    setForm(newEdit);
  };

  const phoneNoChanged = (value, data, event, formattedValue) => {
    setForm({ ...form, phone_no: formattedValue });
  };

  const validateForm = () => {
    let newEdit = { ...errors };
    let validateData = true;
    if (form.first_name === '') {
      newEdit.first_name = __trans('error_msg.first_name_error');
      validateData = false;
    } else {
      newEdit.first_name = '';
    }
    if (form.last_name === '') {
      newEdit.last_name = __trans('error_msg.last_name_error');
      validateData = false;
    } else {
      newEdit.last_name = '';
    }
    if (form.email === '') {
      newEdit.email = __trans('error_msg.email_error');
      validateData = false;
    } else {
      newEdit.email = '';
      if (!helpers.isValidEmail(form.email)) {
        newEdit.email = __trans('error_msg.email_invalid');
        validateData = false;
      }
    }
    if (form.phone_no === '') {
      newEdit.phone_no = __trans('error_msg.phone_no_error');
      validateData = false;
    } else {
      newEdit.phone_no = '';
      if (form.phone_no.length < 9) {
        newEdit.phone_no = __trans('error_msg.phone_no_invalid');
        validateData = false;
      }
      /*if (!helpers.validateNumber(form.phone_no)) {
				newEdit.phone_no = __trans('error_msg.phone_no_invalid');
				validateData = false;
			}*/
    }

    // if (form.company_name === '') {
    // 	newEdit.company_name = __trans('error_msg.company_name_error');
    // 	validateData = false;
    // } else {
    // 	newEdit.company_name = '';
    // }

    if (form.cuil_cuit === '') {
      newEdit.cuil_cuit = __trans('error_msg.field_required_error');
      validateData = false;
    } else {
      newEdit.cuil_cuit = '';
    }

    if (form.cuil_cuit_number === '') {
      newEdit.cuil_cuit_number = __trans('error_msg.field_required_error');
      validateData = false;
    } else {
      newEdit.cuil_cuit_number = '';
    }

    /*if (form.phone_country_code === '') {
			newEdit.phone_country_code = __trans('error_msg.field_required_error');
			validateData = false;
		} else {
			newEdit.phone_country_code = '';
		}*/

    /*if (form.extension === '') {
			newEdit.extension = __trans('error_msg.field_required_error');
			validateData = false;
		} else {
			newEdit.extension = '';
		}*/

    if (form.tax_address === '') {
      newEdit.tax_address = __trans('error_msg.field_required_error');
      validateData = false;
    } else {
      newEdit.tax_address = '';
    }

    /*if (form.address_line_1 === '') {
			newEdit.address_line_1 = __trans('error_msg.field_required_error');
			validateData = false;
		} else {
			newEdit.address_line_1 = '';
		}*/

    if (form.city === 0) {
      newEdit.city = __trans('error_msg.field_required_error');
      validateData = false;
    } else {
      newEdit.city = '';
    }

    if (form.province === 0) {
      newEdit.province = __trans('error_msg.field_required_error');
      validateData = false;
    } else {
      newEdit.province = '';
    }

    if (form.country === 0) {
      newEdit.country = __trans('error_msg.field_required_error');
      validateData = false;
    } else {
      newEdit.country = '';
    }

    if (form.zip_code === '') {
      newEdit.zip_code = __trans('error_msg.field_required_error');
      validateData = false;
    } else {
      newEdit.zip_code = '';
    }

    setErrors(newEdit);
    return validateData;
  };

  const handleSubmit = (e) => {
    //Before submit validate form
    if (validateForm()) {
      submitForm({
        first_name: form.first_name,
        last_name: form.last_name,
        email: form.email,
        company_name: form.company_name,
        cuil_cuit: form.cuil_cuit,
        cuil_cuit_number: form.cuil_cuit_number,
        phone_country_code: form.phone_country_code,
        phone_no: form.phone_no,
        extension: form.extension,
        address: form.tax_address,
        address_line_1: form.address_line_1,
        city: form.city,
        state: form.province,
        country: form.country,
        zip_code: form.zip_code,
        status: 1,
      });
    }
  };

  const submitForm = async (formData) => {
    setSubmitControle({ ...submitcontrol, status: 'active' });
    var submit_url = id === '0' ? 'users/add-client' : 'users/update-client/' + id;
    await makeRequest(submit_url, formData, 'POST', addError)
      .then((res) => {
        dispatch(callCommonAction({ reloaddata: true }));
        setSubmitControle({ ...submitcontrol, status: 'finished' });
        if (res.type === 'success') {
          handleCancel();
          setOpen(false);
        }
      })
      .catch((error) => {
        setSubmitControle({ ...submitcontrol, status: 'finished' });
      });
  };

  const handleCancel = () => {
    setForm({
      first_name: '',
      last_name: '',
      email: '',
      company_name: '',
      // cuil_cuit: '',
      // cuil_cuit_number: '',
      phone_country_code: '',
      phone_no: '',
      extension: '',
      tax_address: '',
      address_line_1: '',
      city: 0,
      province: 0,
      country: 0,
      zip_code: '',
    });
    setErrors({
      first_name: '',
      last_name: '',
      email: '',
      company_name: '',
      // cuil_cuit: '',
      // cuil_cuit_number: '',
      phone_country_code: '',
      phone_no: '',
      extension: '',
      tax_address: '',
      address_line_1: '',
      city: '',
      province: '',
      country: '',
      zip_code: '',
    });
    setOpen(false);
  };

  const prepareFormLabel = (text) => {
    return (
      <div>
        {text}
        <span className="text-danger"></span>
      </div>
    );
  };

  return (
    <Modal
      className="header-border two-buttons add-client-modal"
      open={open}
      onRequestClose={() => handleCancel()}
      modalLabel=""
      modalHeading={id === '0' ? __trans('add_client_text') : __trans('edit_client_text')}
      preventCloseOnClickOutside={true}
      primaryButtonText={__trans('submit_button')}
      secondaryButtonText={__trans('cancel_button')}
      onRequestSubmit={() => handleSubmit()}
      loadingStatus={submitcontrol.status}
      loadingDescription={submitcontrol.description}
      onLoadingSuccess={resetSubmitControlStatus}
    >
      {!contentLoading ? (
        <Form aria-label="create/update client form">
          <div className="text-end">
            <span className="text-danger">*</span>Required
          </div>
          <Stack>
            <div className="">
              <div className="row mb-2">
                {id !== '0' ? (
                  <div className="col-md-2 px-1">
                    <TextInput
                      type="text"
                      name="client_id"
                      id="client_id"
                      disabled={true}
                      value={form.client_id}
                      onChange={valueChanged}
                      labelText={prepareFormLabel(__trans('forms.client_id_label'))}
                    />
                  </div>
                ) : (
                  ''
                )}
                <div className="col-md-5 px-1">
                  <TextInput
                    type="text"
                    name="first_name"
                    id="first_name"
                    value={form.first_name}
                    onChange={valueChanged}
                    labelText={prepareFormLabel(__trans('forms.first_name'))}
                    placeholder={__trans('forms.first_name_placeholder')}
                    invalid={errors.first_name !== '' ? true : false}
                    invalidText={errors.first_name}
                  />
                </div>
                <div className="col-md-5 px-1">
                  <TextInput
                    type="text"
                    name="last_name"
                    id="last_name"
                    value={form.last_name}
                    onChange={valueChanged}
                    labelText={prepareFormLabel(__trans('forms.last_name'))}
                    placeholder={__trans('forms.last_name_placeholder')}
                    invalid={errors.last_name !== '' ? true : false}
                    invalidText={errors.last_name}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-5 px-1">
                  <TextInput
                    type="text"
                    name="company_name"
                    id="company_name"
                    value={form.company_name}
                    onChange={valueChanged}
                    labelText={prepareFormLabel(__trans('forms.company_name_label'))}
                    placeholder={__trans('forms.company_name_placeholder')}
                  />
                </div>
                <div className="col-md-7 px-1">
                  <TextInput
                    type="email"
                    name="email"
                    id="firm_email"
                    value={form.email}
                    onChange={valueChanged}
                    labelText={prepareFormLabel(__trans('forms.email_label'))}
                    placeholder={__trans('forms.email_placeholder')}
                    invalid={errors.email !== '' ? true : false}
                    invalidText={errors.email}
                  />
                </div>
              </div>
              <div className="row mb-2">
                {/* <div className="col-md-2 px-1">
                  <Select
                    name="cuil_cuit"
                    id="cuil_cuit"
                    value={form.cuil_cuit}
                    onChange={valueChanged}
                    labelText={prepareFormLabel(__trans('forms.cuil_cuit_label'))}
                    placeholder={__trans('forms.cuil_cuit_placeholder')}
                    invalid={errors.cuil_cuit !== '' ? true : false}
                    invalidText={errors.cuil}
                  >
                    <SelectItem value="" text="Select" />
                    <SelectItem value="CUIL" text="CUIL" />
                    <SelectItem value="CUIT" text="CUIT" />
                  </Select>
                </div>
                <div className="col-md-3 px-1">
                  <TextInput
                    type="text"
                    name="cuil_cuit_number"
                    id="cuil_cuit_number"
                    value={form.cuil_cuit_number}
                    onChange={valueChanged}
                    labelText={prepareFormLabel(__trans('forms.cuil_cuit_number_label'))}
                    placeholder={__trans('forms.cuil_cuit_number_placeholder')}
                    invalid={errors.cuil_cuit_number !== '' ? true : false}
                    invalidText={errors.cuil_cuit_number}
                  />
                </div> */}
                <div className="col-md-4 px-1">
                  <div className="cds--form-item cds--text-input-wrapper">
                    <div className="cds--text-input__label-wrapper">
                      <label labelfor="phone_no" className="cds--label" dir="auto">
                        {__trans('forms.phone_no_label')}
                      </label>
                    </div>
                    <div className="cds--text-input__field-outer-wrapper ">
                      <div className="cds--text-input__field-wrapper" data-invalid="true">
                        <PhoneInput
                          className=""
                          country={'us'}
                          enableSearch={true}
                          disableSearchIcon={true}
                          value={form.phone_no}
                          onChange={(value, country, e, formattedValue) =>
                            phoneNoChanged(value, country, e, formattedValue)
                          }
                        />
                      </div>
                      <div className="cds--form-requirement" id="phone_no-error-msg" dir="auto">
                        {errors.phone_no}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 px-1">
                  <TextInput
                    type="text"
                    name="extension"
                    id="extension"
                    value={form.extension}
                    onChange={valueChanged}
                    labelText={prepareFormLabel(__trans('forms.extension_label'))}
                    placeholder={__trans('forms.extension_placeholder')}
                    invalid={errors.extension !== '' ? true : false}
                    invalidText={errors.extension}
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-md-7 px-1">
                  <TextInput
                    type="text"
                    name="tax_address"
                    id="tax_address"
                    value={form.tax_address}
                    onChange={valueChanged}
                    labelText={prepareFormLabel(__trans('forms.address_label'))}
                    placeholder={__trans('forms.address_placeholder')}
                    invalid={errors.tax_address !== '' ? true : false}
                    invalidText={errors.tax_address}
                  />
                </div>
                <div className="col-md-5 px-1">
                  <TextInput
                    type="text"
                    name="address_line_1"
                    id="address_line_1"
                    value={form.address_line_1}
                    onChange={valueChanged}
                    labelText={prepareFormLabel(__trans('forms.address_line1_label'))}
                    placeholder={__trans('forms.address_line1_placeholder')}
                    invalid={errors.address_line_1 !== '' ? true : false}
                    invalidText={errors.address_line_1}
                  />
                </div>
              </div>

              <div className="row mb-0">
                <div className="col-md-3 px-1">
                  <Select
                    name="country"
                    id="country"
                    value={form.country}
                    onChange={valueChanged}
                    labelText={prepareFormLabel(__trans('forms.country_label'))}
                    placeholder={__trans('forms.country_placeholder')}
                    invalid={errors.country !== '' ? true : false}
                    invalidText={errors.country}
                  >
                    <SelectItem value="" text="Select" />
                    {countries.length > 0
                      ? countries.map((item, index) => (
                          <SelectItem value={item.id} text={item.name} />
                        ))
                      : ''}
                  </Select>
                </div>
                <div className="col-md-3 px-1">
                  <Select
                    name="province"
                    id="province"
                    value={form.province}
                    onChange={valueChanged}
                    labelText={prepareFormLabel(__trans('forms.province_label'))}
                    placeholder={__trans('forms.province_placeholder')}
                    invalid={errors.province !== '' ? true : false}
                    invalidText={errors.province}
                  >
                    <SelectItem value="" text="Select" />
                    {states.length > 0
                      ? states.map((item, index) => <SelectItem value={item.id} text={item.name} />)
                      : ''}
                  </Select>
                </div>
                <div className="col-md-3 px-1">
                  <Select
                    name="city"
                    id="city"
                    value={form.city}
                    onChange={valueChanged}
                    labelText={prepareFormLabel(__trans('forms.city_label'))}
                    placeholder={__trans('forms.city_placeholder')}
                    invalid={errors.city !== '' ? true : false}
                    invalidText={errors.city}
                  >
                    <SelectItem value="" text="Select" />
                    {cities.length > 0
                      ? cities.map((item, index) => <SelectItem value={item.id} text={item.name} />)
                      : ''}
                  </Select>
                </div>
                <div className="col-md-3 px-1">
                  <TextInput
                    type="text"
                    name="zip_code"
                    id="zip_code"
                    value={form.zip_code}
                    onChange={valueChanged}
                    labelText={prepareFormLabel(__trans('forms.zip_code_label'))}
                    placeholder={__trans('forms.zip_code_placeholder')}
                    invalid={errors.zip_code !== '' ? true : false}
                    invalidText={errors.zip_code}
                  />
                </div>
              </div>
            </div>
          </Stack>
        </Form>
      ) : (
        <div className="d-flex justify-content-center">
          <Loading className="text-center" withOverlay={false} />
        </div>
      )}
    </Modal>
  );
};

export default CreateUpdateClient;
