import DummyImage from '../assets/images/avatar.png';

export function userSubscriptionStatus(key) {
  const user = JSON.parse(localStorage.getItem('user'));
  //console.log('user', user)
  if (user) {
    if (user.user_type === 1) {
      return true;
    } else if (user.user_type === 2) {
      const firm = JSON.parse(localStorage.getItem('firm'));
      if (firm) {
        if (firm.plan_id === null) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
}
