import React, { useState } from 'react';
import { Modal, Stack, Select, SelectItem } from 'carbon-components-react';
import { useDispatch } from 'react-redux';
import { callCommonAction } from '../../redux/Common/CommonReducer';
import useToastAlert from '../../hooks/useToastAlert';
import { __trans, makeRequest } from '../../_helpers';

const ChangeStatus = ({ open, setOpen, module, selectedItems, reloadData }) => {
  const { addError } = useToastAlert();
  const dispatch = useDispatch();
  const [submitcontrol, setSubmitControle] = useState({
    status: 'inactive',
    description: __trans('submit_button'),
  });
  const [form, setForm] = useState({ status: '', status_error: '' });

  const resetSubmitControlStatus = () => {
    setSubmitControle({
      ...submitcontrol,
      status: 'inactive',
      description: __trans('submit_button'),
    });
  };

  const valueChanged = (e) => {
    const { name, value } = e.target;
    let newEdit = { ...form };
    newEdit[name] = value;
    newEdit[name + '_error'] = '';
    setForm(newEdit);
  };

  const validateForm = () => {
    let newEdit = { ...form };
    let validateData = true;
    if (form.status === '') {
      newEdit.status_error = __trans('error_msg.question_error');
      validateData = false;
    } else {
      newEdit.status_error = '';
    }
    setForm(newEdit);
    return validateData;
  };

  const handleSubmit = (e) => {
    //console.log('handle submit clicked');
    //console.log('form data', form);
    //Before submit validate form
    if (validateForm()) {
      //console.log('form is valid')
      submitForm({ status: form.status, items: JSON.stringify(selectedItems) });
      //submitForm(form)
    }
  };

  const submitForm = async (formData) => {
    //console.log('submit form data', formData)
    setSubmitControle({ ...submitcontrol, status: 'active' });
    var submit_url = module + '/update-status';
    await makeRequest(submit_url, formData, 'POST', addError)
      .then((res) => {
        dispatch(callCommonAction({ reloaddata: true }));
        setSubmitControle({ ...submitcontrol, status: 'finished' });
        if (res.type === 'success') {
          let ln_msg = __trans('server_msg.' + res.message);
          addError(ln_msg, res.type);
          setForm({ status: '', status_error: '' });
          reloadData(true);
          //setTimeout(() => {
          setOpen(false);
          //}, 2000);
        }
      })
      .catch((error) => {
        setSubmitControle({ ...submitcontrol, status: 'finished' });
      });
  };

  const handleCancel = () => {
    setForm({ status: '', status_error: '' });
    setOpen(false);
  };

  return (
    <Modal
      className=""
      open={open}
      onRequestClose={() => handleCancel()}
      modalHeading={__trans('common.update_status_page_heading')}
      modalLabel={__trans('common.update_status_page_info')}
      preventCloseOnClickOutside={true}
      primaryButtonText={__trans('submit_button')}
      secondaryButtonText={__trans('cancel_button')}
      onRequestSubmit={() => handleSubmit()}
      loadingStatus={submitcontrol.status}
      loadingDescription={submitcontrol.description}
      onLoadingSuccess={resetSubmitControlStatus}
    >
      <Stack gap={5}>
        <Select
          name="status"
          id="status"
          value={form.status}
          labelText={__trans('forms.status_label')}
          invalid={form.status_error !== '' ? true : false}
          invalidText={form.status_error}
          onChange={valueChanged}
        >
          <SelectItem disabled value="" text={__trans('forms.status_placeholder')} />
          <SelectItem value="1" text="Active" />
          <SelectItem value="2" text="Inactive" />
        </Select>
      </Stack>
    </Modal>
  );
};

export default ChangeStatus;
