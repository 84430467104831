import React, { useEffect, Suspense } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { userDetails, userSubscriptionStatus, checkUserLoggedIn } from '../../../_helpers';
// routes config
import routes from '../../../routes';

const MainContent = () => {
  const navigate = useNavigate();
  const checkUser = () => {
    //console.log('localStorage.getItem("token")', localStorage.getItem("token"))
    if (!checkUserLoggedIn()) {
      navigate('login');
    }
    if (checkUserLoggedIn() && userDetails('role') !== 'Admin' && !userSubscriptionStatus()) {
      navigate('buy-subscription');
    }
    if (checkUserLoggedIn() && userDetails('role') === 'Client' && !userDetails('client_setup')) {
      navigate('client-welcome');
    }
  };

  useEffect(() => {
    //Check if user token exist then redirect him to dashboard
    checkUser();
  }, []);

  return (
    <div>
      <Suspense>
        <Routes>
          {routes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={
                    localStorage.getItem('token') !== null ? (
                      <route.element />
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />
              )
            );
          })}
          {/*<Route path="/" element={<Navigate to="login" replace />} />*/}
        </Routes>
      </Suspense>
    </div>
  );
};

export default MainContent;
