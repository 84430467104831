import Moment from 'moment';
import { __trans, userDetails } from '../_helpers';
const { REACT_APP_PAYPAL_MODE } = process.env;

const helpers = {
  isEmpty: function (text) {
    return !(text && text.length > 0 && text.trim().length > 0);
  },
  isValidEmail: function (email) {
    //return /\S+@\S+\.\S+/.test(email);
    return /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
      email
    );
  },
  validateNumber: function (number) {
    var pattern = /^[0-9]{10}$/;
    return pattern.test(number); // returns a boolean
  },
  validatePhoneNumber: function (number) {
    var pattern = /^[+]{1}(?:[0-9\-\\(\\)\\/.]\s?){6,15}[0-9]{1}$/;
    return pattern.test(number); // returns a boolean
  },
  validateOTP: function (number) {
    var pattern = /^[0-9]{6}$/;
    return pattern.test(number); // returns a boolean
  },
  allowNoOnly: function (number, req_length) {
    number = number.replace(/[^0-9]/g, '');
    if (req_length > 0 && number.length > req_length) {
      number = number.slice(0, req_length);
    }
    return number;
    //return number.replace(/[^0-9]/g, "");
  },
  isValidFormatForPassword: function (passwordInputValue) {
    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const digitsRegExp = /(?=.*?[0-9])/;
    const specialCharRegExp = /['!@#$%^&*()_+~`|}{[\]:;?><,./-=']/;
    const minLengthRegExp = /.{8,}/;
    const passwordLength = passwordInputValue.length;
    const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
    const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
    const digitsPassword = digitsRegExp.test(passwordInputValue);
    const specialCharPassword = specialCharRegExp.test(passwordInputValue);
    const minLengthPassword = minLengthRegExp.test(passwordInputValue);
    let errMsg = '';
    if (passwordLength === 0) {
      errMsg = __trans('error_msg.password_empty');
    } else if (
      !uppercasePassword ||
      !lowercasePassword ||
      !digitsPassword ||
      !specialCharPassword ||
      !minLengthPassword
    ) {
      errMsg = __trans('error_msg.password_invalid');
    } else {
      errMsg = '';
    }
    return errMsg;
  },
  truncateString: function (str, n, useWordBoundary) {
    if (str.length <= n) {
      return str;
    }
    const subString = str.substr(0, n - 1); // the original check
    return (useWordBoundary ? subString.substr(0, subString.lastIndexOf(' ')) : subString) + '...';
  },
  diffYMDHMS: function (date1) {
    date1 = Moment(date1);
    let date2 = Moment();
    let years = date1.diff(date2, 'year');
    date2.add(years, 'years');
    let months = date1.diff(date2, 'months');
    date2.add(months, 'months');
    let days = date1.diff(date2, 'days');
    date2.add(days, 'days');
    let hours = date1.diff(date2, 'hours');
    date2.add(hours, 'hours');
    let minutes = date1.diff(date2, 'minutes');
    date2.add(minutes, 'minutes');
    //let seconds = date1.diff(date2, 'seconds');
    if (days > 0 || hours > 0 || minutes > 0) {
      var new_string = '';
      new_string += days > 0 ? days + 'd,' : '';
      new_string += hours > 0 ? hours + 'h,' : '';
      new_string += minutes > 0 ? minutes + 'm' : '';
      return new_string;
    } else {
      return '';
    }
  },
  getParsedDate: function (date) {
    date = String(date).split(' ');
    var days = String(date[0]).split('-');
    var hours = String(date[1]).split(':');
    return [
      parseInt(days[0]),
      parseInt(days[1]) - 1,
      parseInt(days[2]),
      parseInt(hours[0]),
      parseInt(hours[1]),
      parseInt(hours[2]),
    ];
  },
  paypalDetail: function (key) {
    const env = REACT_APP_PAYPAL_MODE?.toLowerCase() || 'sandbox';
    if (env === 'sandbox') {
      return process.env['REACT_APP_PAYPAL_SANDBOX_' + key];
    } else {
      return process.env['REACT_APP_PAYPAL_LIVE_' + key];
    }
  },
  randomString: function (len) {
    var p = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    return [...Array(len)].reduce((a) => a + p[~~(Math.random() * p.length)], '');
  },
  formatAmount: function (amount) {
    var settings = { thousands: ',', decimal: '.', integer: false };
    var inputValue = amount;
    inputValue = inputValue.replace(/\D/g, '');
    if (!settings.integer) {
      inputValue = inputValue.replace(/(\d{2})$/, settings.decimal.concat('$1'));
      inputValue = inputValue.replace(
        /(\d+)(\d{3}, \d{2})$/g,
        '$1'.concat(settings.thousands).concat('$2')
      );
    }
    //add thousand seprator
    var totalThousandsPoints = (inputValue.length - 3) / 3;
    var thousandsPointsAdded = 0;
    while (totalThousandsPoints > thousandsPointsAdded) {
      thousandsPointsAdded++;
      inputValue = inputValue.replace(
        /(\d+)(\d{3}.*)/,
        '$1'.concat(settings.thousands).concat('$2')
      );
    }
    //remove left zero
    inputValue = inputValue.replace(/^(0)(\d)/g, '$2');
    //console.log('formatAmount inputValue', inputValue)
    return inputValue;
  },

  tableLoaderProps: function (headers) {
    var rows = process.env.REACT_APP_TABLE_LOADER_ROWS
      ? process.env.REACT_APP_TABLE_LOADER_ROWS
      : 4;
    return { rows_count: rows, colomn_count: headers.length, headers: headers };
  },

  mungeEmailAddress: function (s) {
    var i = s.indexOf('@');
    var startIndex = (i * 0.2) | 0;
    var endIndex = (i * 0.9) | 0;
    return (
      s.slice(0, startIndex) + s.slice(startIndex, endIndex).replace(/./g, '.') + s.slice(endIndex)
    );
  },

  mungePhoneNoAddress: function (str) {
    const startString = str.substr(0, 3);
    const endString = str.substr(-1, 2);
    return startString + ' xxxxxxxx ' + endString;
  },

  statusNameColor: function (status, value) {
    let result;
    switch (value) {
      case 'color':
        result = status === 1 ? 'green' : 'red';
        break;
      case 'name':
        result = status === 1 ? 'Active' : 'In-Active';
        break;
      default:
        result = '';
    }
    return result;
  },
  getActiveLanguage: function () {
    const lang = localStorage.getItem('user_language');
    var use_lang = 'en';
    if (lang) {
      use_lang = lang === 'sp' ? 'sp' : 'en';
    }
    return use_lang;
  },
  processRequestData: function (row, columnId, TableCell, Tag) {
    if (columnId === 'client_id') {
      return (
        <TableCell key={columnId}>{row['client_id'] ? row['client_id'].client_id : ''}</TableCell>
      );
    } else if (columnId === 'first_name') {
      return <TableCell>{row['client_id'] ? row['client_id'].first_name : ''}</TableCell>;
    } else if (columnId === 'last_name') {
      return <TableCell>{row['client_id'] ? row['client_id'].last_name : ''}</TableCell>;
    } else if (columnId === 'company') {
      return (
        <TableCell className="text-nowrap" key={columnId}>
          {row['client_id']['client_profile_id'] !== null &&
          row['client_id']['client_profile_id'] !== undefined
            ? row['client_id']['client_profile_id'].company_name
            : ''}
        </TableCell>
      );
    } else if (columnId === 'status') {
      return (
        <TableCell>
          <Tag className="table-badge" type={helpers.statusNameColor(row[columnId], 'color')}>
            {helpers.statusNameColor(row[columnId], 'name')}
          </Tag>
        </TableCell>
      );
    } else if (columnId === 'year') {
      return (
        <TableCell className="text-nowrap" key={columnId}>
          {row['firm_id'] !== null && row['firm_id'] !== undefined ? row['firm_id'].year : 'YYYY'}
        </TableCell>
      );
    } else if (columnId === 'client_status') {
      var status_text = __trans('client_not_started');
      var icon_img = '/images/client-status-icons/not-started.png';
      if (row['client_status'] === 1) {
        status_text = __trans('client_in_progress');
        if (row['complete_percent'] < '50') {
          icon_img = '/images/client-status-icons/progress1.png';
        } else if (row['complete_percent'] === '50') {
          icon_img = '/images/client-status-icons/progress2.png';
        } else {
          icon_img = '/images/client-status-icons/progress3.png';
        }
      } else if (row['client_status'] === 2) {
        status_text = __trans('client_completed');
        icon_img = '/images/client-status-icons/completed.png';
      } else if (row['client_status'] === 3) {
        status_text = __trans('client_error');
        icon_img = '/images/client-status-icons/error.png';
      }
      return (
        <TableCell className="text-nowrap" key={columnId}>
          <div className="d-flex align-items-center gap-1">
            <img src={icon_img} alt="status-icon" /> {status_text}
          </div>
        </TableCell>
      );
    } else if (columnId === 'deleted_at') {
      return (
        <TableCell className="" key={columnId}>
          {row[columnId] !== null ? Moment(row[columnId]).format('MM/DD/YYYY') : '-'}
        </TableCell>
      );
    } else if (columnId === 'send_at') {
      return (
        <TableCell className="" key={columnId}>
          {row[columnId] !== null ? Moment(row[columnId]).format('MM/DD/YYYY') : '-'}
        </TableCell>
      );
    } else if (columnId === 'last_activity') {
      var lang = helpers.getActiveLanguage();
      return (
        <TableCell className="" key={columnId}>
          {Moment(row[columnId]).locale(lang).fromNow()}
        </TableCell>
      );
    } else if (columnId === 'signatures') {
      let signature_display = 'N/A';
      if (row.sign_document === 0) {
        return (
          <TableCell className="" key={columnId}>
            {signature_display}
          </TableCell>
        );
      } else {
        let tag_type = helpers.getTagColor(row.document_signatured);
        return (
          <TableCell className="" key={columnId}>
            <Tag
              className={`${tag_type === 'green' ? 'green' : 'grey-outline'} count-tag`}
              type={tag_type}
              size="md"
            >
              {row.document_signatured}
            </Tag>
          </TableCell>
        );
      }
    } else if (columnId === 'questions') {
      let question_display = 'N/A';
      if (row.question_template_id === null) {
        return (
          <TableCell className="" key={columnId}>
            {question_display}
          </TableCell>
        );
      } else {
        let tag_type = helpers.getTagColor(row.question_answered);
        return (
          <TableCell className="" key={columnId}>
            <Tag
              className={`${tag_type === 'green' ? 'green' : 'grey-outline'} count-tag`}
              type={tag_type}
              size="md"
            >
              {row.question_answered}
            </Tag>
          </TableCell>
        );
      }
    } else if (columnId === 'documents') {
      let document_display = 'N/A';
      if (row.documents_template_id === null) {
        return (
          <TableCell className="" key={columnId}>
            {document_display}
          </TableCell>
        );
      } else {
        let tag_type = helpers.getTagColor(row.document_uploaded);
        return (
          <TableCell className="" key={columnId}>
            <Tag
              className={`${tag_type === 'green' ? 'green' : 'grey-outline'} count-tag`}
              type={tag_type}
              size="md"
            >
              {row.document_uploaded}
            </Tag>
          </TableCell>
        );
      }
    } else if (columnId === 'notes') {
      return (
        <TableCell className="text-nowrap" key={columnId}>
          <img src="/images/icons/notes.png" alt="notes-icon" />
        </TableCell>
      );
    } else {
      return (
        <TableCell className="" key={columnId}>
          {row[columnId]}
        </TableCell>
      );
    }
  },

  getTagColor: function (value) {
    const parts = value.split('/');
    const firstValue = parts[0];
    const secondValue = parts[1];
    return firstValue === secondValue ? 'green' : 'outline';
  },

  checkCanDeleteEditItem: function (row) {
    if (row.visible_to === 1 && userDetails('role') === 'Admin') {
      return true;
    } else if (
      row.visible_to === 2 &&
      userDetails('role') !== 'Admin' &&
      row.firm_id === userDetails('firm_id')
    ) {
      return true;
    }
    return false;
  },

  formatBytes: function (bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return 'n/a';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes} ${sizes[i]})`;
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
  },
};

export default helpers;
