import React, { useEffect, useState } from 'react';
import { Modal, TextInput, Tag } from '@carbon/react';
import useToastAlert from '../../../../hooks/useToastAlert';
import { __trans, makeRequest, userDetails } from '../../../../_helpers';

const BuyRequest = ({ open, setOpen, setPaymentOpen, buyform, setBuyForm }) => {
  const { addError } = useToastAlert();
  const [requestpricing, setRequestPricing] = useState({});

  const getRequestPricing = async () => {
    var country_id = userDetails('country');
    await makeRequest(
      'request_price/get-request-pricing',
      { country_id: country_id },
      'POST',
      addError
    )
      .then((res) => {
        //console.log('res', res)
        if (res.type === 'success') {
          setRequestPricing(res.data);
          var unit_price = res.data.price;
          var total_price = buyform.request_purchase * unit_price;
          setBuyForm({ ...buyform, price: total_price, unit_price: unit_price });
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (open) {
      getRequestPricing();
    }
  }, [open]);

  const valueChanged = (e) => {
    //console.log('e', e)
    const { name, value } = e.target;
    if (value > 0 || value === '') {
      var unit_price = requestpricing.price;
      var total_price = value * unit_price;
      setBuyForm({
        ...buyform,
        request_purchase: value,
        price: total_price,
        request_purchase_error: '',
      });
    }
    //updatePrice();
    /*console.log('name', name)
		console.log('value', value)
		let newEdit = { ...form };
		newEdit[name] = value;
		newEdit[name + '_error'] = '';
		setForm(newEdit);
		updatePrice();*/
  };

  const validateForm = () => {
    let newEdit = { ...buyform };
    let validateData = true;

    if (buyform.request_purchase === 0) {
      newEdit.request_purchase_error = __trans('error_msg.request_purchase_error');
      validateData = false;
    } else {
      newEdit.request_purchase_error = '';
    }

    setBuyForm(newEdit);
    return validateData;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      console.log('form is valid');
      setBuyForm({ ...buyform, buy_now: true });
      setOpen(false);
      setPaymentOpen(true);
    }
  };

  const handleCancel = () => {
    setBuyForm({ ...buyform, request_purchase: 50, request_purchase_error: '' });
    setOpen(false);
  };

  return (
    <Modal
      className="header-border two-buttons"
      size="sm"
      open={open}
      onRequestClose={() => handleCancel()}
      modalHeading={__trans('setting_buy_request')}
      modalLabel=""
      preventCloseOnClickOutside={true}
      primaryButtonText={__trans('buy_now_button')}
      secondaryButtonText={__trans('cancel_button')}
      onSecondarySubmit={() => handleCancel()}
      onRequestSubmit={() => handleSubmit()}
    >
      <div className="row">
        <div className="mb-3">
          <TextInput
            type="number"
            name="request_purchase"
            id="request_purchase"
            value={buyform.request_purchase}
            onChange={valueChanged}
            labelText={__trans('forms.request_purchase')}
            placeholder={__trans('forms.request_purchase_placeholder')}
            invalid={buyform.request_purchase_error !== '' ? true : false}
            invalidText={buyform.request_purchase_error}
          />
        </div>
        <div className="d-flex justify-content-between mb-5">
          <div className="">
            <Tag className="" type="blue">
              {__trans('request_buy_amount')}: ${buyform.price}
            </Tag>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BuyRequest;
