/**
 * @param row A table row.
 * @param searchString A search string.
 * @returns `true` if the given table row matches the given search string.
 */
const doesRowMatchSearchString = (row, searchString) =>
  Object.keys(row).some(
    (key) =>
      key !== '_id' &&
      String(row[key] ?? '')
        .toLowerCase()
        .indexOf(searchString.toLowerCase()) >= 0
  );

export default doesRowMatchSearchString;
