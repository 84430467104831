import React, { useState } from 'react';
import { Button, TextArea, InlineLoading } from 'carbon-components-react';
import { ArrowLeft } from '@carbon/icons-react';
import { useSelector, useDispatch } from 'react-redux';
import { callCommonAction } from '../../../../redux/Common/CommonReducer';
import useToastAlert from '../../../../hooks/useToastAlert';
import { __trans, makeRequest } from '../../../../_helpers';

const ClientFeedback = ({ currentstep, handleNextPrevoius, requestdata, setRequestData }) => {
  const { addError } = useToastAlert();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.common);
  const [clientmessage, setClientMessage] = useState(requestdata.message_from_client);

  /* handle on change input  */
  const handleInputChange = (e) => {
    setClientMessage(e.target.value);
  };

  const handleSubmit = async () => {
    if (clientmessage.trim() !== '') {
      dispatch(callCommonAction({ loading: true }));
      await makeRequest(
        'firm/save-client-feedback/' + requestdata._id,
        { message_from_client: clientmessage },
        'POST',
        addError
      )
        .then((res) => {
          dispatch(callCommonAction({ loading: false }));
          if (res.type === 'success') {
            //handleNextPrevoius(currentstep + 1)
            setRequestData(res.data);
          }
        })
        .catch((error) => {
          dispatch(callCommonAction({ loading: false }));
        });
    } else {
      addError(__trans('server_msg.client_request_updated_successfully'), 'success');
    }
  };

  return (
    <>
      <div className="container">
        <div className="mt-5 mb-5">
          <div className="row">
            <div className="cds--data-table-header">
              <h4 className="cds--data-table-header__title" id="tc-:r1dn:-title">
                {__trans('account_setup_step4_info')}
              </h4>
              <p className="cds--data-table-header__description" id="tc-:r1dn:-description">
                {__trans('account_setup_feedback_optional')}
              </p>
            </div>
            <div className="col">
              <div className="col-md-8">
                <TextArea
                  className="textarea-white border"
                  value={clientmessage}
                  onChange={handleInputChange}
                  labelText=""
                  rows={16}
                  id="text-area-1"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="my-3 text-end">
          {!loading ? (
            <>
              <Button
                kind="secondary"
                renderIcon={ArrowLeft}
                size="md"
                onClick={() => handleNextPrevoius(currentstep - 1)}
              >
                Previous
              </Button>
              <Button className="btn--primary" size="md" onClick={handleSubmit}>
                <span className="pe-3">Finish</span>{' '}
              </Button>
            </>
          ) : (
            <Button kind="secondary" size="md" as="div" role="button">
              <InlineLoading
                className="text-info"
                style={{ marginLeft: '1rem' }}
                description="Wait..."
                status="active"
                aria-live="off"
              />
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default ClientFeedback;
