import React from 'react';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { InlineLoading } from 'carbon-components-react';
import { useNavigate } from 'react-router-dom';
import useToastAlert from '../../../../hooks/useToastAlert';
import { makeRequest } from '../../../../_helpers';

const PayPalButton = ({ planID, itemprice }) => {
  const { addError } = useToastAlert();
  const navigate = useNavigate();
  const [{ isPending }] = usePayPalScriptReducer();

  const createOrder = () => {
    console.log('create order function called');
    return makeRequest(
      'subscription-order/create-order',
      { plan_id: planID, quantity: 1, price: itemprice },
      'POST',
      addError
    )
      .then((res) => {
        //console.log('order response', res)
        if (res.type === 'success') {
          return res.data.order_id;
        }
      })
      .catch((error) => {
        //console.log('error', error)
      });
  };

  const onApprove = (data) => {
    return makeRequest(
      'subscription-order/capture-paypal-order',
      { payment_source: data.paymentSource, orderID: data.orderID },
      'POST',
      addError
    )
      .then((res) => {
        if (res.type === 'success') {
          localStorage.removeItem('firm');
          localStorage.setItem('firm', JSON.stringify(res.firm));
          navigate('/dashboard');
        }
      })
      .catch((error) => {});
  };
  return (
    <>
      {isPending ? (
        <InlineLoading
          className="d-flex justify-content-center"
          status="active"
          iconDescription="Loading"
          description="Loading ..."
        />
      ) : null}
      <PayPalButtons createOrder={createOrder} onApprove={onApprove} />
    </>
  );
};

export default PayPalButton;
