import { NavLink } from 'react-router-dom';
import { UserMultiple, Template, TableOfContents, Forum } from '@carbon/icons-react';
import { __trans } from '../../../_helpers';

const AdminRightNav = () => {
  return (
    <ul className="sidebar-links">
      <li>
        <NavLink to="/firm-list" activeclassname="active">
          <UserMultiple />
          {__trans('admin_sidebar.firms')}
        </NavLink>
      </li>
      <li>
        <NavLink to="/request-price-list" activeclassname="active">
          <TableOfContents />
          {__trans('admin_sidebar.request_price')}
        </NavLink>
      </li>
      <li>
        <NavLink to="templates" activeclassname="active">
          <Template />
          {__trans('admin_sidebar.template_management')}
        </NavLink>
      </li>
      <li>
        <NavLink to="/contactus-list" activeclassname="active">
          <Forum />
          {__trans('admin_sidebar.contactus_management')}
        </NavLink>
      </li>
    </ul>
  );
};

export default AdminRightNav;
