import SideBar from './default-layout-partials/SideBar';
import MainContent from './default-layout-partials/MainContent';
import ToastNotification from '../common/ToastNotification';
import LanguageSwitcher from '../common/LanguageSwitcher';

const DefaultLayout = () => {
  return (
    <div id="wrapper">
      <SideBar />
      <div id="content-wrapper">
        <div id="content">
          <div className="d-flex justify-content-end">
            <ToastNotification />
          </div>
          <div className="row">
            <div className="container-fluid">
              <div className="row">
                <div className="col"></div>
                <LanguageSwitcher />
              </div>
              <MainContent />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
