import { __trans } from '../../../_helpers';

export const Header = [
  {
    id: 'client_id',
    title: __trans('firm_table_head.customer'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'first_name',
    title: __trans('firm_table_head.first_name'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'last_name',
    title: __trans('firm_table_head.last_name'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'email',
    title: __trans('firm_table_head.email'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'phone_no',
    title: __trans('firm_table_head.phone_no'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'firm_id',
    title: __trans('firm_table_head.company'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'status',
    title: __trans('firm_table_head.status'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'actions',
    title: __trans('firm_table_head.actions'),
  },
];
