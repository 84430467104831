import React, { useState, useEffect } from 'react';
import { Modal, Form, Stack, TextInput, Loading } from '@carbon/react';
import { useSelector, useDispatch } from 'react-redux';
import { callCommonAction } from '../../../../redux/Common/CommonReducer';
import useToastAlert from '../../../../hooks/useToastAlert';
import helpers from '../../../../_helpers/common';
import { __trans, makeRequest } from '../../../../_helpers';

const CreateUpdateStaff = ({ open, setOpen, id }) => {
  //console.log('item id', id)
  const { addError } = useToastAlert();
  const { contentLoading } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const [submitcontrol, setSubmitControle] = useState({
    status: 'inactive',
    description: __trans('submit_button'),
  });
  const [form, setForm] = useState({
    first_name: '',
    first_name_error: '',
    last_name: '',
    last_name_error: '',
    email: '',
    email_error: '',
    phone_no: '',
    phone_no_error: '',
    licence_no: '',
    licence_no_error: '',
    status: 1,
  });

  const resetSubmitControlStatus = () => {
    setSubmitControle({
      ...submitcontrol,
      status: 'inactive',
      description: __trans('submit_button'),
    });
  };

  const getFormData = async () => {
    if (id !== '0') {
      dispatch(callCommonAction({ contentLoading: true }));
      await makeRequest('users/detail/' + id, {}, 'POST', addError)
        .then((res) => {
          dispatch(callCommonAction({ contentLoading: false }));
          if (res.type === 'success') {
            setForm({
              ...form,
              first_name: res.data.first_name,
              last_name: res.data.last_name,
              email: res.data.email,
              phone_no: res.data.phone_no,
              licence_no: res.data.licence_no,
              status: res.data.status,
            });
          } else {
            addError(res.message, res.type);
          }
        })
        .catch((error) => {
          addError(error.message, 'error');
        });
    }
  };

  useEffect(() => {
    if (open) {
      getFormData();
    }
  }, [open]);

  const valueChanged = (e) => {
    const { name, value } = e.target;
    let newEdit = { ...form };
    newEdit[name] = value;
    newEdit[name + '_error'] = '';
    setForm(newEdit);
  };

  const validateForm = () => {
    let newEdit = { ...form };
    let validateData = true;
    if (form.first_name === '') {
      newEdit.first_name_error = __trans('error_msg.first_name_error');
      validateData = false;
    } else {
      newEdit.first_name_error = '';
    }
    if (form.last_name === '') {
      newEdit.last_name_error = __trans('error_msg.last_name_error');
      validateData = false;
    } else {
      newEdit.last_name_error = '';
    }
    if (form.email === '') {
      newEdit.email_error = __trans('error_msg.email_error');
      validateData = false;
    } else {
      newEdit.email_error = '';
      if (!helpers.isValidEmail(form.email)) {
        newEdit.email_error = __trans('error_msg.email_invalid');
        validateData = false;
      }
    }
    if (form.phone_no === '') {
      newEdit.phone_no_error = __trans('error_msg.phone_no_error');
      validateData = false;
    } else {
      newEdit.phone_no_error = '';
      if (!helpers.validateNumber(form.phone_no)) {
        newEdit.phone_no_error = __trans('error_msg.phone_no_invalid');
        validateData = false;
      }
    }
    if (form.licence_no === '') {
      newEdit.licence_no_error = __trans('error_msg.licence_no_error');
      validateData = false;
    } else {
      newEdit.licence_no_error = '';
    }
    setForm(newEdit);
    return validateData;
  };

  const handleSubmit = (e) => {
    //Before submit validate form
    if (validateForm()) {
      submitForm({
        first_name: form.first_name,
        last_name: form.last_name,
        email: form.email,
        phone_no: form.phone_no,
        licence_no: form.licence_no,
        status: form.status,
      });
    }
  };

  const submitForm = async (formData) => {
    setSubmitControle({ ...submitcontrol, status: 'active' });
    var submit_url = id === '0' ? 'users/add-staff' : 'users/update-staff/' + id;
    await makeRequest(submit_url, formData, 'POST', addError)
      .then((res) => {
        setSubmitControle({ ...submitcontrol, status: 'finished' });
        if (res.type === 'success') {
          dispatch(callCommonAction({ reloaddata: true }));
          setForm({
            first_name: '',
            first_name_error: '',
            last_name: '',
            last_name_error: '',
            email: '',
            email_error: '',
            phone_no: '',
            phone_no_error: '',
            licence_no: '',
            licence_no_error: '',
          });
          //setTimeout(() => {
          setOpen(false);
          //}, 2000);
        }
      })
      .catch((error) => {
        setSubmitControle({ ...submitcontrol, status: 'finished' });
      });
  };

  const handleCancel = () => {
    setForm({
      first_name: '',
      first_name_error: '',
      last_name: '',
      last_name_error: '',
      email: '',
      email_error: '',
      phone_no: '',
      phone_no_error: '',
      licence_no: '',
      licence_no_error: '',
    });
    setOpen(false);
  };
  return (
    <Modal
      className="header-border two-buttons add-client-modal"
      open={open}
      onRequestClose={() => handleCancel()}
      modalLabel=""
      modalHeading={id === '0' ? __trans('add_staff_text') : __trans('edit_staff_text')}
      preventCloseOnClickOutside={true}
      primaryButtonText={__trans('submit_button')}
      secondaryButtonText={__trans('cancel_button')}
      onRequestSubmit={() => handleSubmit()}
      loadingStatus={submitcontrol.status}
      loadingDescription={submitcontrol.description}
      onLoadingSuccess={resetSubmitControlStatus}
    >
      {!contentLoading ? (
        <Form aria-label="create/update template form">
          <Stack gap={1}>
            <div className="row">
              <div className="col-6 mt-2">
                <TextInput
                  type="text"
                  name="first_name"
                  id="first_name"
                  value={form.first_name}
                  onChange={valueChanged}
                  labelText={__trans('forms.first_name')}
                  placeholder={__trans('forms.first_name_placeholder')}
                  invalid={form.first_name_error !== '' ? true : false}
                  invalidText={form.first_name_error}
                />
              </div>
              <div className="col-6 mt-2">
                <TextInput
                  type="text"
                  name="last_name"
                  id="last_name"
                  value={form.last_name}
                  onChange={valueChanged}
                  labelText={__trans('forms.last_name')}
                  placeholder={__trans('forms.last_name_placeholder')}
                  invalid={form.last_name_error !== '' ? true : false}
                  invalidText={form.last_name_error}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6 mt-2">
                <TextInput
                  type="email"
                  name="email"
                  id="firm_email"
                  value={form.email}
                  onChange={valueChanged}
                  labelText={__trans('forms.email_label')}
                  placeholder={__trans('forms.email_placeholder')}
                  invalid={form.email_error !== '' ? true : false}
                  invalidText={form.email_error}
                />
              </div>
              <div className="col-6 mt-2">
                <TextInput
                  type="text"
                  name="phone_no"
                  id="phone_no"
                  value={form.phone_no}
                  onChange={valueChanged}
                  labelText={__trans('forms.phone_no_label')}
                  placeholder={__trans('forms.phone_no_placeholder')}
                  invalid={form.phone_no_error !== '' ? true : false}
                  invalidText={form.phone_no_error}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-2">
                <TextInput
                  type="text"
                  name="licence_no"
                  id="licence_no"
                  value={form.licence_no}
                  onChange={valueChanged}
                  labelText={__trans('forms.licence_no_label')}
                  placeholder={__trans('forms.licence_no_placeholder')}
                  invalid={form.licence_no_error !== '' ? true : false}
                  invalidText={form.licence_no_error}
                />
              </div>
            </div>
          </Stack>
        </Form>
      ) : (
        <div className="d-flex justify-content-center">
          <Loading className="text-center" withOverlay={false} />
        </div>
      )}
    </Modal>
  );
};

export default CreateUpdateStaff;
