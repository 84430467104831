import { userDetails } from './_helpers';
//Admin Component
import AdminDashboard from './components/admin/dashboard/Dashboard';
import FirmList from './components/admin/firm/List';
import RequestPriceList from './components/admin/requestprice/List';
import ContactUsList from './components/admin/contactus/List';
//Firm Component
import StaffList from './components/firm/staff/List';
import StaffLogs from './components/firm/staff/logs/List';
import NewRequest from './components/firm/new-request/NewRequest';
import InProcessClientList from './components/firm/in-process-list/List';
import DeliveredClientList from './components/firm/delivered-list/List';
import DeletedClientList from './components/firm/deleted-list/List';
import ArchivedClientList from './components/firm/archived-list/List';
import ClientDocumentList from './components/firm/document-list/List';
import RequestPurchaseList from './components/firm/request-purchase-history/List';
import Setting from './components/firm/setting/Setting';
import ClientList from './components/firm/client/List';
//Common Component
import CreateUpdateTemplate from './components/common/template/create-update-template/CreateUpdateTemplate';
import TemplateList from './components/common/template/List';

const routes = [
  { path: '/dashboard', name: 'Admin Dashboard', element: AdminDashboard },
  { path: '/firm-list', name: 'Firm List', element: FirmList },
  { path: '/request-price-list', name: 'Request Price List', element: RequestPriceList },
  { path: '/templates', name: 'Template List', element: TemplateList },
  {
    path: '/template/create-update/:id',
    name: 'Create Update Template',
    element: CreateUpdateTemplate,
  },
  { path: '/contactus-list', name: 'Contact Us List', element: ContactUsList },
  { path: '/clients/new-request/:id', name: 'New Request', element: NewRequest },
  { path: '/clients/in-progress-list', name: 'In Process', element: InProcessClientList },
  { path: '/clients/delivered-list', name: 'Delivered List', element: DeliveredClientList },
  { path: '/clients/deleted-list', name: 'Deleted List', element: DeletedClientList },
  { path: '/clients/archive-list', name: 'Archived List', element: ArchivedClientList },
  { path: '/clients/document-list', name: 'Client Document List', element: ClientDocumentList },
  { path: '/client-list', name: 'Client List', element: ClientList },
  { path: '/request-purchase-list', name: 'Request Purchase List', element: RequestPurchaseList },
  { path: '/settings', name: 'Setting', element: Setting },
  { path: '/templates', name: 'Template List', element: TemplateList },
  {
    path: '/template/create-update/:id',
    name: 'Create Update Template',
    element: CreateUpdateTemplate,
  },
  { path: '/staff-list', name: 'Staff List', element: StaffList },
  { path: '/staff/view-log/:staff_id', name: 'Staff Logs', element: StaffLogs },
];

export default routes;
