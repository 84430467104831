import React, { useState } from 'react';
import { Form, TextInput, TextArea, Button, InlineLoading } from '@carbon/react';
import { useSelector, useDispatch } from 'react-redux';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import WebLayout from '../../layout/WebLayout';
import useToastAlert from '../../../hooks/useToastAlert';
import helpers from '../../../_helpers/common';
import { __trans, makeRequest } from '../../../_helpers';

const ContactUs = () => {
  const { addError } = useToastAlert();
  const { loading } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    first_name: '',
    first_name_error: '',
    last_name: '',
    last_name_error: '',
    email: '',
    email_error: '',
    phone_no: '',
    phone_no_error: '',
    message: '',
    message_error: '',
  });

  const valueChanged = (e) => {
    const { name, value } = e.target;
    let newEdit = { ...form };
    newEdit[name] = value;
    newEdit[name + '_error'] = '';
    setForm(newEdit);
  };

  const validateForm = () => {
    let newEdit = { ...form };
    let validateData = true;
    if (form.first_name === '') {
      newEdit.first_name_error = __trans('error_msg.first_name_error');
      validateData = false;
    } else {
      newEdit.first_name_error = '';
    }
    if (form.last_name === '') {
      newEdit.last_name_error = __trans('error_msg.last_name_error');
      validateData = false;
    } else {
      newEdit.last_name_error = '';
    }
    if (form.email === '') {
      newEdit.email_error = __trans('error_msg.email_error');
      validateData = false;
    } else {
      newEdit.email_error = '';
      if (!helpers.isValidEmail(form.email)) {
        newEdit.email_error = __trans('error_msg.email_invalid');
        validateData = false;
      }
    }
    if (form.phone_no === '') {
      newEdit.phone_no_error = __trans('error_msg.phone_no_error');
      validateData = false;
    } else {
      newEdit.phone_no_error = '';
      if (!helpers.validateNumber(form.phone_no)) {
        newEdit.phone_no_error = __trans('error_msg.phone_no_invalid');
        validateData = false;
      }
    }
    if (form.message === '') {
      newEdit.message_error = __trans('error_msg.message_error');
      validateData = false;
    } else {
      newEdit.message_error = '';
    }

    setForm(newEdit);
    return validateData;
  };

  const handleSubmit = (e) => {
    //Before submit validate form
    if (validateForm()) {
      submitForm({
        first_name: form.first_name,
        last_name: form.last_name,
        email: form.email,
        phone_no: form.phone_no,
        message: form.message,
      });
    }
  };

  const submitForm = async (formData) => {
    dispatch(callCommonAction({ loading: true }));
    await makeRequest('contact-us/create', formData, 'POST', addError)
      .then((res) => {
        dispatch(callCommonAction({ loading: false }));
        if (res.type === 'success') {
          setForm({
            first_name: '',
            first_name_error: '',
            last_name: '',
            last_name_error: '',
            email: '',
            email_error: '',
            phone_no: '',
            phone_no_error: '',
            message: '',
            message_error: '',
          });
        }
      })
      .catch((error) => {
        dispatch(callCommonAction({ loading: false }));
      });
  };
  return (
    <WebLayout>
      <Form>
        <div className="form-heading mb-5 pt-3">
          <h3 className="mb-3">{__trans('contactus_page_heading')} </h3>
          <p>{__trans('contactus_page_title')} </p>
        </div>
        <div className="row mb-4">
          <div className="col">
            <TextInput
              type="text"
              name="first_name"
              id="first_name"
              value={form.first_name}
              onChange={valueChanged}
              labelText={__trans('forms.first_name')}
              placeholder={__trans('forms.first_name_placeholder')}
              invalid={form.first_name_error !== '' ? true : false}
              invalidText={form.first_name_error}
            />
          </div>
          <div className="col">
            <TextInput
              type="text"
              name="last_name"
              id="last_name"
              value={form.last_name}
              onChange={valueChanged}
              labelText={__trans('forms.last_name')}
              placeholder={__trans('forms.last_name_placeholder')}
              invalid={form.last_name_error !== '' ? true : false}
              invalidText={form.last_name_error}
            />
          </div>
        </div>
        <div className="mb-4">
          <TextInput
            type="email"
            name="email"
            id="email"
            value={form.email}
            onChange={valueChanged}
            labelText={__trans('forms.email_label')}
            placeholder={__trans('forms.email_placeholder')}
            invalid={form.email_error !== '' ? true : false}
            invalidText={form.email_error}
          />
        </div>
        <div className="mb-4">
          <TextInput
            type="text"
            name="phone_no"
            id="phone_no"
            value={form.phone_no}
            onChange={valueChanged}
            labelText={__trans('forms.phone_no_label')}
            placeholder={__trans('forms.phone_no_placeholder')}
            invalid={form.phone_no_error !== '' ? true : false}
            invalidText={form.phone_no_error}
          />
        </div>
        <div className="mb-4">
          <TextArea
            name="message"
            id="message"
            rows={4}
            value={form.message}
            onChange={valueChanged}
            labelText={__trans('forms.message_label')}
            placeholder={__trans('forms.message_placeholder')}
            invalid={form.message_error !== '' ? true : false}
            invalidText={form.message_error}
          />
        </div>
        <div className="mt-4 mb-4">
          {!loading ? (
            <Button className="btn btn-primary" onClick={handleSubmit} size="md">
              {__trans('continue_button')}
            </Button>
          ) : (
            <Button kind="secondary" size="md" as="div" role="button">
              <InlineLoading
                className="text-info"
                style={{ marginLeft: '1rem' }}
                description="Wait..."
                status="active"
                aria-live="off"
              />
            </Button>
          )}
        </div>
      </Form>
    </WebLayout>
  );
};

export default ContactUs;
