const en_lang = require('../locales/en/translation.json');
const sp_lang = require('../locales/sp/translation.json');

export function __trans(key, data = {}) {
  //console.log('lang_key', key)
  //console.log('data', data)
  // console.log('data.length', Object.getOwnPropertyNames(data).length)
  const lang = localStorage.getItem('user_language');
  var use_lang = en_lang;
  if (lang) {
    use_lang = lang === 'sp' ? sp_lang : en_lang;
  }

  var key_array = key.split('.');
  //console.log('key_array', key_array)
  //const enData = [...en_lang];
  var lang_string = '';
  if (key_array.length === 1) {
    lang_string = use_lang[key_array[0]];
    //return sp_lang[key_array[0]];
  } else if (key_array.length === 2) {
    //return sp_lang[key_array[0]][key_array[1]];
    lang_string = use_lang[key_array[0]][key_array[1]];
  }
  if (Object.getOwnPropertyNames(data).length > 0) {
    Object.keys(data).forEach((key) => {
      //const value = data[key];
      lang_string = lang_string.replace(':' + key, data[key]);
      //console.log(`Key: ${key}, Value: ${value}`);
    });
  }
  return lang_string;
}
