import { NavLink } from 'react-router-dom';
import {
  Document,
  SendAlt,
  TrashCan,
  Archive,
  Folders,
  UserMultiple,
  Template,
  Settings,
  GroupPresentation,
} from '@carbon/icons-react';
import { __trans } from '../../../_helpers';

const FirmRightNav = () => {
  return (
    <ul className="sidebar-links">
      <li>
        <NavLink to="clients/in-progress-list" activeclassname="active">
          <Document />
          {__trans('admin_sidebar.in_progress_client')}
        </NavLink>
      </li>
      <li>
        <NavLink to="clients/delivered-list" activeclassname="active">
          <SendAlt />
          {__trans('admin_sidebar.delivered_client')}
        </NavLink>
      </li>
      <li>
        <NavLink to="clients/deleted-list" activeclassname="active">
          <TrashCan />
          {__trans('admin_sidebar.deleted_client')}
        </NavLink>
      </li>
      <li>
        <NavLink to="clients/archive-list" activeclassname="active">
          <Archive />
          {__trans('admin_sidebar.archive_client')}
        </NavLink>
      </li>
      <li>
        <NavLink to="clients/document-list" activeclassname="active">
          <Folders />
          {__trans('admin_sidebar.client_documents')}
        </NavLink>
      </li>
      <li>
        <NavLink to="client-list" activeclassname="active">
          <UserMultiple />
          {__trans('admin_sidebar.client_managment')}
        </NavLink>
      </li>
      <li>
        <NavLink to="staff-list" activeclassname="active">
          <GroupPresentation />
          {__trans('admin_sidebar.staff_managment')}
        </NavLink>
      </li>
      <li>
        <NavLink to="templates" activeclassname="active">
          <Template />
          {__trans('admin_sidebar.template_management')}
        </NavLink>
      </li>
      <li>
        <NavLink to="settings" activeclassname="active">
          <Settings />
          {__trans('admin_sidebar.settings')}
        </NavLink>
      </li>
    </ul>
  );
};

export default FirmRightNav;
