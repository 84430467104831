import { __trans } from '../../../../_helpers';

export const Header = [
  {
    id: '_id',
    title: __trans('staff_log_table_header.s_no'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'message',
    title: __trans('staff_log_table_header.message'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'created_at',
    title: __trans('staff_log_table_header.created_at'),
    sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'status',
    title: __trans('staff_log_table_header.status'),
    sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'actions',
    title: __trans('staff_log_table_header.actions'),
  },
];
