import React, { useEffect, useState } from 'react';
import { Form, TextInput, Button, InlineLoading } from '@carbon/react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import AuthLayout from '../../layout/AuthLayout';
import useToastAlert from '../../../hooks/useToastAlert';
import helpers from '../../../_helpers/common';
import { __trans, makeRequest, checkUserLoggedIn, userDetails } from '../../../_helpers';

const verification_otp_on = 'email';

const VerifyOTP = () => {
  //console.log('comming to send verification code');
  const { addError } = useToastAlert();
  const { loading } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otpsendcount, setOTPSendCount] = useState(1);
  const [form, setForm] = useState({ otp: '', otp_error: '' });

  useEffect(() => {
    //Check if user logged in otherwise redirect him to login page
    checkUserIsLoggedIn();
  });

  const checkUserIsLoggedIn = () => {
    if (!localStorage.getItem('user')) {
      navigate('/login');
    }
  };

  const getUserDetail = (key) => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      if (key === 'email') {
        return helpers.mungeEmailAddress(user.email);
      } else if (key === 'phone_no') {
        return helpers.mungePhoneNoAddress(user.phone_no);
      } else if (key === 'id') {
        return user._id;
      }
    }
  };

  const valueChanged = (e) => {
    const { name, value } = e.target;
    //console.log('name', name);
    //console.log('new value', helpers.allowNoOnly(value, 6));
    let newEdit = { ...form };
    newEdit[name] = helpers.allowNoOnly(value, 6);
    newEdit[name + '_error'] = '';
    setForm(newEdit);
  };

  const validateForm = () => {
    let newEdit = { ...form };
    let validateData = true;
    if (helpers.isEmpty(form.otp)) {
      newEdit.otp_error = __trans('error_msg.verification_code_error');
      validateData = false;
    } else {
      newEdit.otp_error = '';
    }
    setForm(newEdit);
    return validateData;
  };

  const handleSubmit = (e) => {
    //Before submit validate form
    if (validateForm()) {
      submitForm({ otp: form.otp, user_id: getUserDetail('id') });
    }
  };

  const submitForm = async (formData) => {
    dispatch(callCommonAction({ loading: true }));
    await makeRequest('users/verify-otp', formData, 'POST', addError)
      .then((res) => {
        dispatch(callCommonAction({ loading: false }));
        if (res.type === 'success') {
          localStorage.setItem('token', res.jwttoken);
          var role = userDetails('role');
          //console.log('logged in user role', role);
          if (role === 'Firm' || role === 'Staff') {
            navigate('/clients/in-progress-list');
          } else {
            navigate('/dashboard');
          }
        }
      })
      .catch((error) => {
        dispatch(callCommonAction({ loading: false }));
      });
  };

  const resendOTP = async () => {
    dispatch(callCommonAction({ loading: true }));
    const formData = { send_on: verification_otp_on, user_id: getUserDetail('id') };
    await makeRequest('users/send-verification-otp', formData, 'POST', addError)
      .then((res) => {
        dispatch(callCommonAction({ loading: false }));
        if (res.type === 'success') {
          setOTPSendCount(res.send_count);
        }
      })
      .catch((error) => {
        dispatch(callCommonAction({ loading: false }));
      });
  };
  return (
    <AuthLayout>
      <Form className="signup-form mb-3">
        <div className="form-heading mb-5 pt-3">
          <h3 className="mb-3">{__trans('verify_page_heading')}</h3>
          <p>
            {verification_otp_on === 'email'
              ? __trans('verify_page_title_email', {
                  send_count: otpsendcount,
                  email: getUserDetail('email'),
                })
              : __trans('verify_page_title_phone', {
                  send_count: otpsendcount,
                  phone_no: getUserDetail('phone_no'),
                })}{' '}
          </p>
        </div>
        <div className="row mb-4">
          <div className="col">
            <TextInput
              type="text"
              name="otp"
              id="otp"
              value={form.otp}
              onChange={valueChanged}
              labelText={__trans('forms.verification_otp')}
              placeholder={__trans('forms.verification_otp_placeholder')}
              invalid={form.otp_error !== '' ? true : false}
              invalidText={form.otp_error}
            />
          </div>
        </div>
        <div className="mt-4 mb-4">
          {!loading ? (
            <Button className="btn btn-primary" onClick={handleSubmit} size="md">
              {__trans('verify_button')}
            </Button>
          ) : (
            <Button kind="secondary" size="md" as="div" role="button">
              <InlineLoading
                className="text-info"
                style={{ marginLeft: '1rem' }}
                description="Wait..."
                status="active"
                aria-live="off"
              />
            </Button>
          )}
        </div>
        <div className="text-center mt-3">
          {__trans('code_not_recived')}{' '}
          <span
            role="button"
            onClick={resendOTP}
            className="text-decoration-none fs-14 text-primary"
          >
            {__trans('resend')}
          </span>
        </div>
        <div className="text-center mt-3">
          {__trans('need_help')}{' '}
          <Link
            to="/contact-us"
            target={'_blank'}
            className="text-decoration-none fs-14 text-primary"
          >
            {__trans('contact_us')}
          </Link>
        </div>
      </Form>
    </AuthLayout>
  );
};

export default VerifyOTP;
