import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Checkbox,
  TextInput,
  Select,
  SelectItem,
  RadioButton,
  RadioButtonGroup,
  CheckboxGroup,
  Loading,
} from 'carbon-components-react';
import { Edit, Restart } from '@carbon/icons-react';
import { Modal } from '@carbon/react';
import { useSelector, useDispatch } from 'react-redux';
import useToastAlert from '../../../hooks/useToastAlert';
import { __trans, makeRequest, getData } from '../../../_helpers';

const QuestionAnswerPreview = ({ open, setOpen, id, form, setForm, setNextStep }) => {
  const { addError } = useToastAlert();
  const navigate = useNavigate();
  const { contentLoading } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const [template, setTemplate] = useState({});
  const [formData, setFormData] = useState({});
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [submitcontrol, setSubmitControle] = useState({
    status: 'inactive',
    description: __trans('submit_button'),
  });

  const resetSubmitControlStatus = () => {
    setSubmitControle({
      ...submitcontrol,
      status: 'inactive',
      description: __trans('submit_button'),
    });
  };

  const getQuestionAnswer = async () => {
    if (form.question_template_id !== '') {
      //await getData("template/get-client-template-question-answer/" + form.question_template_id, {}, 'POST', addError, dispatch)
      await getData(
        'template/get-template-question/' + form.question_template_id,
        {},
        'POST',
        addError,
        dispatch
      ).then((res) => {
        //console.log('question res', res)
        if (res.type === 'success') {
          setFormData(res.questions[0].question);
          //setFormValues(res.answer.answers)
          setTemplate(res.template);
        }
      });
    }
  };

  useEffect(() => {
    if (open) {
      getQuestionAnswer();
    }
  }, [open]);

  /* handle on change input  */
  const handleInputChange = (item, e) => {
    let value = e.target.value;
    const id = item._id;
    setFormValues({ ...formValues, [id]: value });

    if (!value || value <= '0') {
      //console.log(value,'value')
      let msg = __trans('error_msg.field_required_error');
      if (value <= '0' && value !== '') {
        msg = `This field is must be greater than 0.`;
      }
      setFormErrors({ ...formErrors, [id]: msg });
    } else {
      const { [id]: _, ...rest } = formErrors;
      setFormErrors(rest);
    }
  };

  const renderFormElement = (item, index) => {
    const id = item._id;
    switch (item.type) {
      case 'number':
        return (
          <TextInput
            type={item.type}
            name={id}
            className="bg-transparent"
            value=""
            onChange={(e) => handleInputChange(item, e)}
            labelText=""
            placeholder=""
            invalid={formErrors[id] !== undefined && formErrors[id] !== '' ? true : false}
            invalidText={
              formErrors[id] !== undefined && formErrors[id] !== '' ? formErrors[id] : ''
            }
          />
        );
      case 'text':
        return (
          <TextInput
            type={item.type}
            name={id}
            className="bg-transparent"
            value=""
            onChange={(e) => handleInputChange(item, e)}
            labelText=""
            placeholder=""
            invalid={formErrors[id] !== undefined && formErrors[id] !== '' ? true : false}
            invalidText={
              formErrors[id] !== undefined && formErrors[id] !== '' ? formErrors[id] : ''
            }
          />
        );
      case 'select':
        return (
          <Select
            name={id}
            id={id}
            labelText=""
            className="bg-transparent"
            value=""
            onChange={(e) => handleInputChange(item, e)}
            invalid={formErrors[id] !== undefined && formErrors[id] !== '' ? true : false}
            invalidText={
              formErrors[id] !== undefined && formErrors[id] !== '' ? formErrors[id] : ''
            }
          >
            <SelectItem disabled value="" text="Select" />
            {item.question_options.map((option, optInd) => {
              return <SelectItem key={option._id} value={option._id} text={option.label} />;
            })}
          </Select>
        );
      case 'checkbox':
        return (
          <CheckboxGroup
            name={id}
            legendText=""
            value=""
            onChange={(e) => handleInputChange(item, e)}
            invalid={formErrors[id] !== undefined && formErrors[id] !== '' ? true : false}
            invalidText={
              formErrors[id] !== undefined && formErrors[id] !== '' ? formErrors[id] : ''
            }
          >
            {item.question_options.map((option, optInd) => {
              return (
                <Checkbox
                  key={option._id}
                  labelText={option.label}
                  value={option.value}
                  id={option._id}
                />
              );
            })}
          </CheckboxGroup>
        );
      case 'radio':
        return (
          <RadioButtonGroup
            name={id}
            legendText=""
            orientation="vertical"
            value=""
            onChange={(e) => handleInputChange(item, e)}
            invalid={formErrors[id] !== undefined && formErrors[id] !== '' ? true : false}
            invalidText={
              formErrors[id] !== undefined && formErrors[id] !== '' ? formErrors[id] : ''
            }
          >
            {item.question_options.map((option, optInd) => {
              return (
                <RadioButton
                  value={option.value}
                  name="radio-button_demo"
                  id={option._id}
                  labelText={option.label}
                />
              );
            })}
          </RadioButtonGroup>
        );
      default:
        return <p>Default Component</p>;
    }
  };

  const handleSubmit = () => {
    setNextStep(6);
  };

  const saveAndClose = async () => {
    //console.log('prepare contract save and close');
    //navigate('/clients/in-progress-list');
    var formData = new FormData();
    formData.append(`package_id`, form.package_id);
    formData.append(`client_id`, form.client_id);
    formData.append(`sign_document`, form.sign_document);
    formData.append(`question_template_id`, form.question_template_id);
    formData.append(`documents_template_id`, form.documents_template_id);

    setSubmitControle({ ...submitcontrol, status: 'active' });
    let end_url =
      form.client_request_id === ''
        ? 'firm/create-client-request'
        : 'firm/update-client-request/' + form.client_request_id;
    await makeRequest(end_url, formData, 'POST', addError)
      .then((res) => {
        setSubmitControle({ ...submitcontrol, status: 'finished' });
        if (res.type === 'success') {
          navigate('/clients/in-progress-list');
        }
      })
      .catch((error) => {
        setSubmitControle({ ...submitcontrol, status: 'finished' });
      });
  };

  const handleCancel = () => {
    navigate('/clients/in-progress-list');
  };

  const getEditRoute = () => {
    return '/template/create-update/' + form.question_template_id + '?back_to=templates?tab=1';
  };

  return (
    <Modal
      className="header-border two-buttons arrow-icon"
      open={open}
      onRequestClose={() => handleCancel()}
      modalLabel=""
      modalHeading={__trans('preview_qestion_template')}
      preventCloseOnClickOutside={true}
      primaryButtonText={__trans('continue_button')}
      secondaryButtonText={__trans('save_and_close_button')}
      onSecondarySubmit={() => saveAndClose()}
      onRequestSubmit={() => handleSubmit()}
      loadingStatus={submitcontrol.status}
      loadingDescription={submitcontrol.description}
      onLoadingSuccess={resetSubmitControlStatus}
    >
      {form.question_template_id !== '' ? (
        <section className="questioner-sec p-3">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-9 mx-auto">
                {form.question_template_id !== '' ? (
                  <div className="row mb-2">
                    <div className="col-md-12 d-flex gap-2 justify-content-end">
                      <Link
                        to={getEditRoute()}
                        target="_blank"
                        type="button"
                        className="btn btn-outline-primary btn-sm cstm-btn-small"
                      >
                        {__trans('edit_button')} <Edit />
                      </Link>
                      <button
                        onClick={() => getQuestionAnswer()}
                        type="button"
                        className="btn btn-sm cstm-btn-small btn-outline-secondary"
                      >
                        {__trans('refresh_button')} <Restart />
                      </button>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className="card mb-3 questioner first">
                  <div className="card-body ">
                    <p className="mb-3">{template.name}</p>
                    <span className="text-danger">* Required</span>
                  </div>
                </div>
                {!contentLoading ? (
                  <>
                    {formData.length > 0
                      ? formData.map((item, index) => (
                          <>
                            <div className="card mb-3 questioner">
                              <div className="card-body ">
                                <h4>
                                  {item.name} <span className="text-danger">*</span>
                                </h4>
                                {renderFormElement(item, index)}
                              </div>
                            </div>
                          </>
                        ))
                      : ''}
                  </>
                ) : (
                  <div className="d-flex justify-content-center">
                    <Loading className="text-center" withOverlay={false} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div className="text-center my-5">
          <h5>{__trans('no_question_asked')}</h5>
        </div>
      )}
    </Modal>
  );
};

export default QuestionAnswerPreview;
