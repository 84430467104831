import React, { useState, useEffect } from 'react';
import { Stack } from '@carbon/react';
import {
  Modal,
  TextInput,
  TextArea,
  Select,
  SelectItem,
  Button,
  Loading,
} from 'carbon-components-react';
import { TrashCan as Delete, Add } from '@carbon/icons-react';
import { useSelector, useDispatch } from 'react-redux';
import { callCommonAction } from '../../../../redux/Common/CommonReducer';
import useToastAlert from '../../../../hooks/useToastAlert';
import { __trans, makeRequest, getData } from '../../../../_helpers';
import { GetCountries } from '../../../../_helpers/select-list';
import helpers from '../../../../_helpers/common';

const CreatePlan = ({ open, setOpen, id }) => {
  const { addError } = useToastAlert();
  //console.log('selected id', id);
  const { contentLoading } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);
  const [submitcontrol, setSubmitControle] = useState({
    status: 'inactive',
    description: __trans('submit_button'),
  });
  const [form, setForm] = useState({
    plan_name: '',
    plan_name_error: '',
    plan_price: '',
    plan_price_error: '',
    client_limit: '',
    client_limit_error: '',
    plan_description: '',
    plan_description_error: '',
    country: '',
    country_error: '',
  });
  const [featureItems, setFeatureItems] = useState([{ feature: '', feature_error: '' }]);

  const resetSubmitControlStatus = () => {
    setSubmitControle({
      ...submitcontrol,
      status: 'inactive',
      description: __trans('submit_button'),
    });
  };

  const getFormData = async () => {
    if (id !== '0') {
      await getData('request_price/form-data/' + id, {}, 'POST', addError, dispatch)
        .then((res) => {
          if (res.type === 'success') {
            setForm({
              ...form,
              plan_name: res.data.name,
              plan_price: res.data.price,
              plan_description: res.data.description,
              country: res.data.country,
            });
          }
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    if (open) {
      getCountryData();
      getFormData();
    }
  }, [open]);

  /***Get Available Country List**********************/
  const getCountryData = async () => {
    await GetCountries().then((data) => {
      setCountries(data);
    });
  };

  const valueChanged = (e) => {
    const { name, value } = e.target;
    let newEdit = { ...form };
    newEdit[name] = value;

    newEdit[name + '_error'] = '';
    setForm(newEdit);
  };

  const handleDeleteInput = (index) => {
    const newArray = [...featureItems];
    newArray.splice(index, 1);
    setFeatureItems(newArray);
  };

  const handleChange = (event, index) => {
    let { name, value } = event.target;
    let changevalue = [...featureItems];
    changevalue[index][name] = value;
    setFeatureItems(changevalue);
  };

  const addFeatureItem = () => {
    setFeatureItems([...featureItems, { feature: '', feature_error: '' }]);
  };

  const formatValuesAndChange = (e) => {
    //if (e.target.value.length <= 18) {
    let format_amount = helpers.formatAmount(e.target.value);
    setForm({ ...form, plan_price: format_amount });
    //}
  };

  const validateForm = () => {
    let newEdit = { ...form };
    let validateData = true;

    if (helpers.isEmpty(form.plan_name)) {
      newEdit.plan_name_error = __trans('error_msg.plan_name_error');
      validateData = false;
    } else {
      newEdit.plan_name_error = '';
    }

    if (form.plan_price === '') {
      newEdit.plan_price_error = __trans('error_msg.plan_price_error');
      validateData = false;
    } else {
      newEdit.plan_price_error = '';
    }

    if (form.country === '') {
      newEdit.country_error = __trans('error_msg.plan_price_error');
      validateData = false;
    } else {
      newEdit.country_error = '';
    }

    setForm(newEdit);
    return validateData;
  };

  const handleSubmit = (e) => {
    //Before submit validate form
    if (validateForm()) {
      var feature_data = {};
      featureItems.forEach(function (item, index) {
        feature_data[index] = item.feature;
        //feature_data.push(item.feature);
      });
      submitForm({
        name: form.plan_name,
        price: form.plan_price,
        description: form.plan_description,
        country: form.country,
      });
    }
  };

  const submitForm = async (formData) => {
    setSubmitControle({ ...submitcontrol, status: 'active' });
    var submit_url = id === '0' ? 'request_price/create-plan' : 'request_price/update-plan/' + id;
    await makeRequest(submit_url, formData, 'POST', addError)
      .then((res) => {
        dispatch(callCommonAction({ reloaddata: true }));
        setSubmitControle({ ...submitcontrol, status: 'finished' });
        if (res.type === 'success') {
          setForm({
            plan_name: '',
            plan_name_error: '',
            plan_price: '',
            plan_price_error: '',
            plan_description: '',
            plan_description_error: '',
            country: '',
            country_error: '',
          });
          //setTimeout(() => {
          setOpen(false);
          //}, 2000);
        }
      })
      .catch((error) => {
        setSubmitControle({ ...submitcontrol, status: 'finished' });
      });
  };

  const handleCancel = () => {
    setForm({
      plan_name: '',
      plan_name_error: '',
      plan_price: '',
      plan_price_error: '',
      plan_description: '',
      plan_description_error: '',
      country: '',
      country_error: '',
    });
    setOpen(false);
  };

  return (
    <Modal
      className="header-border two-buttons"
      open={open}
      onRequestClose={() => handleCancel()}
      modalHeading={
        id === '0' ? __trans('add_subscription_text') : __trans('edit_subscription_text')
      }
      modalLabel=""
      preventCloseOnClickOutside={true}
      primaryButtonText={__trans('submit_button')}
      secondaryButtonText={__trans('cancel_button')}
      onRequestSubmit={() => handleSubmit()}
      loadingStatus={submitcontrol.status}
      loadingDescription={submitcontrol.description}
      onLoadingSuccess={resetSubmitControlStatus}
    >
      {!contentLoading ? (
        <Stack gap={5}>
          <TextInput
            type="text"
            name="plan_name"
            id="plan_name"
            value={form.plan_name}
            onChange={valueChanged}
            labelText={__trans('forms.request_price_name')}
            placeholder={__trans('forms.request_price_name_placeholder')}
            invalid={form.plan_name_error !== '' ? true : false}
            invalidText={form.plan_name_error}
          />
          <TextArea
            name="plan_description"
            id="plan_description"
            value={form.plan_description}
            rows={2}
            maxCount={300}
            enableCounter
            onChange={valueChanged}
            labelText={__trans('forms.request_price_description')}
            placeholder={__trans('forms.request_price_description_placeholder')}
            invalid={form.plan_description_error !== '' ? true : false}
            invalidText={form.plan_description_error}
          />
          <Select
            name="country"
            id="country"
            value={form.country}
            onChange={valueChanged}
            labelText={__trans('forms.country_label')}
            placeholder={__trans('forms.country_placeholder')}
            invalid={form.country_error !== '' ? true : false}
            invalidText={form.country_error}
          >
            <SelectItem value="" text="Select" />
            {countries.length > 0
              ? countries.map((item, index) => <SelectItem value={item.id} text={item.name} />)
              : ''}
          </Select>
          <TextInput
            type="text"
            name="plan_price"
            id="plan_price"
            value={form.plan_price}
            onChange={formatValuesAndChange}
            labelText={__trans('forms.request_price')}
            placeholder={__trans('forms.request_price_placeholder')}
            invalid={form.plan_price_error !== '' ? true : false}
            invalidText={form.plan_price_error}
          />
        </Stack>
      ) : (
        <div className="d-flex justify-content-center">
          <Loading className="text-center" withOverlay={false} />
        </div>
      )}
    </Modal>
  );
};

export default CreatePlan;
