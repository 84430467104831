import React, { useCallback, useState, useEffect } from 'react';
import { ButtonSet, Button, Tag } from '@carbon/react';
import { TrashCan as Delete, Edit, Add, SendAlt, View, Column } from '@carbon/icons-react';
import {
  TableContainer,
  Table,
  TableHead,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableSelectRow,
  TableSelectAll,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Pagination,
} from 'carbon-components-react';
import { useRowSelection, useSortInfo, useUniqueId } from '../../../_helpers/tables/table_hooks';
import DeleteConfirmation from '../../common/DeleteConfirmation';
import useToastAlert from '../../../hooks/useToastAlert';
import TableLoader from '../../common/TableLoader';
import TableNoData from '../../common/TableNoData';
import { useSelector, useDispatch } from 'react-redux';
import { TABLE_SIZE, TABLE_SORT_DIRECTION } from '../../../_helpers/tables/table_misc';
import { Header as headers } from './Header';
import helpers from '../../../_helpers/common';
import { __trans, getData, makeRequest } from '../../../_helpers';
import InviteFirm from './invite-firm/InviteFirm';
import ViewProfile from '../../common/profile/ViewProfile';
import CreateUpdateFirm from './create-update/CreateUpdateFirm';

const hasSelection = true;

const List = () => {
  const { addError } = useToastAlert();
  const dispatch = useDispatch();
  const { contentLoading, reloaddata } = useSelector((state) => state.common);
  const [invitefirm, setInviteFirm] = useState(false);
  const [createfirm, setCreateFirm] = useState(false);
  const [deleteitem, setDeleteItem] = useState(false);
  const [viewprofile, setViewProfile] = useState(false);
  const [selecteditem, setSelectedItem] = useState('');
  const [searchString, setSearchString] = useState('');
  const [rows, setRows] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 15,
    total_pages: 0,
    total_item: 0,
  });
  const [sortInfo, setSortInfo] = useSortInfo({
    columnId: '_id',
    direction: TABLE_SORT_DIRECTION.DESCENDING,
  });
  const [setRowSelection] = useRowSelection(rows, '', setRows);
  const selectedRowsCount = rows.filter(({ selected }) => selected).length;
  const selectedAll = selectedRowsCount > 0 && rows.length === selectedRowsCount;
  const hasBatchActions = hasSelection && selectedRowsCount > 0;
  const elementId = useUniqueId('firm_list');
  const selectionAllName = !hasSelection
    ? undefined
    : `__custom-data-table_select-all_${elementId}`;
  const { columnId: sortColumnId, direction: sortDirection } = sortInfo;

  const getFirmList = async (type) => {
    var res = {};
    if (type === 'load') {
      res = await getData(
        'users/firm-list-data',
        { page: pagination.page, limit: pagination.limit, search_string: searchString },
        'POST',
        addError,
        dispatch
      );
    } else {
      res = await makeRequest(
        'users/firm-list-data',
        { page: pagination.page, limit: pagination.limit, search_string: searchString },
        'POST',
        addError
      );
    }

    if (res.type === 'success') {
      setRows(res.data.docs);
      setPagination({
        ...pagination,
        total_pages: Number(res.data.totalPages),
        total_item: Number(res.data.totalDocs),
      });
    }
  };

  useEffect(() => {
    getFirmList('load');
  }, []);

  const handleGetData = useCallback(() => {
    //console.log('comming to use callback');
    getFirmList('load');
  }, []);

  useEffect(() => {
    if (reloaddata) {
      handleGetData();
    }
  }, [reloaddata]);

  useEffect(() => {
    getFirmList('filter');
  }, [pagination.page, pagination.limit, searchString]);

  const viewUserDetail = (id) => {
    setSelectedItem(id);
    setViewProfile(true);
  };

  const handleDeleteItem = (id) => {
    setSelectedItem(id);
    setDeleteItem(true);
  };

  const handleAddEditFirm = (id) => {
    setCreateFirm(true);
    setSelectedItem(id);
  };

  const handleChangeSearchString = useCallback(
    ({ target }) => {
      setSearchString(target.value);
    },
    [setSearchString]
  );

  const handleSearchClear = () => {
    console.log('comming to clear search');
    setSearchString('');
  };

  const handleChangeSelection = useCallback(
    (event) => {
      const { currentTarget } = event;
      const row = currentTarget.closest('tr');
      if (row) {
        setRowSelection(row.dataset.rowId, currentTarget.checked);
      }
    },
    [setRowSelection]
  );

  const handleChangeSelectionAll = useCallback(
    (event) => {
      setRowSelection(undefined, event.currentTarget.checked);
    },
    [setRowSelection]
  );

  const handleChangeSort = useCallback(
    (event) => {
      const { currentTarget } = event;
      const { columnId, sortCycle, sortDirection: oldDirection } = currentTarget.dataset;
      setSortInfo({ columnId, sortCycle, oldDirection });
    },
    [setSortInfo]
  );

  /* const handleDeleteRows = useCallback(() => {
		
	}, []); */

  const handlePaginationChange = (e) => {
    //console.log('pagination event', e)
    setPagination({ ...pagination, page: e.page, limit: e.pageSize });
  };

  const processTableCell = (columnId, row, index) => {
    if (columnId === 'status') {
      return (
        <TableCell key={columnId + index}>
          <Tag
            className="table-badge text-nowrap"
            type={helpers.statusNameColor(row[columnId], 'color')}
          >
            {helpers.statusNameColor(row[columnId], 'name')}
          </Tag>
        </TableCell>
      );
    } else if (columnId === 'phone_no') {
      return (
        <TableCell className="text-nowrap" key={columnId}>
          {row[columnId]}
        </TableCell>
      );
    } else if (columnId === 'firm_id') {
      return (
        <TableCell className="text-nowrap" key={columnId}>
          {row[columnId] !== null && row[columnId] !== undefined ? row[columnId].name : ''}
        </TableCell>
      );
    } else {
      return (
        <TableCell className="" key={columnId}>
          {row[columnId]}
        </TableCell>
      );
    }
  };

  /* eslint-disable no-script-url */
  return (
    <>
      {!contentLoading ? (
        <TableContainer title={__trans('firm_list_title')} description={__trans('firm_list_info')}>
          <TableToolbar size="lg">
            <TableToolbarContent className="align-items-center">
              <TableToolbarSearch
                value={searchString}
                defaultExpanded={searchString.length > 0 ?? true}
                tabIndex={hasBatchActions ? -1 : 0}
                onChange={handleChangeSearchString}
              />
              <Button
                size="sm"
                kind="ghost"
                hasIconOnly
                renderIcon={Delete}
                onClick={() => alert('TableToolbarAction - Delete')}
                iconDescription={__trans('delete_button')}
              />
              <Button
                size="sm"
                kind="ghost"
                hasIconOnly
                renderIcon={Column}
                onClick={() => alert('Column')}
                iconDescription={__trans('colomn_configration_button')}
              />
              <ButtonSet className="">
                <Button
                  onClick={() => handleAddEditFirm('0')}
                  className="btn--primary"
                  size="lg"
                  renderIcon={Add}
                >
                  {__trans('add_firm_button')}
                </Button>
                <Button
                  onClick={() => setInviteFirm(true)}
                  className="btn--primary"
                  size="lg"
                  renderIcon={SendAlt}
                >
                  {__trans('send_invite_button')}
                </Button>
              </ButtonSet>
            </TableToolbarContent>
          </TableToolbar>
          <Table size="sm" isSortable useZebraStyles={true}>
            <TableHead>
              <TableRow>
                {hasSelection && (
                  <TableSelectAll
                    id={`${elementId}--select-all`}
                    checked={selectedAll}
                    indeterminate={selectedRowsCount > 0 && !selectedAll}
                    ariaLabel="Select all rows"
                    name={selectionAllName}
                    onSelect={handleChangeSelectionAll}
                  />
                )}
                {headers.map(({ id: columnId, sortCycle, title }) => {
                  const sortDirectionForThisCell =
                    sortCycle &&
                    (columnId === sortColumnId ? sortDirection : TABLE_SORT_DIRECTION.NONE);
                  return (
                    <TableHeader
                      className="text-nowrap"
                      key={columnId}
                      isSortable={Boolean(sortCycle)}
                      isSortHeader={sortCycle && columnId === sortColumnId}
                      sortDirection={sortDirectionForThisCell}
                      data-column-id={columnId}
                      data-sort-cycle={sortCycle}
                      data-sort-direction={sortDirectionForThisCell}
                      onClick={handleChangeSort}
                    >
                      {title}
                    </TableHeader>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                rows.map((row, index) => {
                  const { id: rowId, selected } = row;
                  const selectionName = !hasSelection
                    ? undefined
                    : `__custom-data-table_${elementId}_${rowId}`;
                  return (
                    <TableRow key={rowId} isSelected={hasSelection && selected} data-row-id={rowId}>
                      {hasSelection && (
                        <TableSelectRow
                          id={`${elementId}--select-${rowId}`}
                          checked={Boolean(selected)}
                          name={selectionName}
                          ariaLabel="Select row"
                          onSelect={handleChangeSelection}
                        />
                      )}
                      {headers.map(({ id: columnId }) => (
                        <>
                          {columnId !== 'actions' ? (
                            <>{processTableCell(columnId, row, index)}</>
                          ) : (
                            <TableCell key={columnId}>
                              <div className="d-flex align-items-center">
                                <Button
                                  size="sm"
                                  kind="ghost"
                                  renderIcon={View}
                                  onClick={() => viewUserDetail(row._id)}
                                  iconDescription={__trans('view_detail_button')}
                                  hasIconOnly
                                />
                                <Button
                                  size="sm"
                                  kind="ghost"
                                  renderIcon={Edit}
                                  onClick={() => handleAddEditFirm(row._id)}
                                  iconDescription={__trans('edit_button')}
                                  hasIconOnly
                                />
                                <Button
                                  size="sm"
                                  kind="ghost"
                                  renderIcon={Delete}
                                  onClick={() => handleDeleteItem(row._id)}
                                  iconDescription={__trans('delete_button')}
                                  hasIconOnly
                                />
                              </div>
                            </TableCell>
                          )}
                        </>
                      ))}
                    </TableRow>
                  );
                })
              ) : (
                <TableNoData headers={headers} />
              )}
            </TableBody>
          </Table>
          <Pagination
            backwardText="Previous page"
            forwardText="Next page"
            itemsPerPageText="Items per page:"
            onChange={(e) => handlePaginationChange(e)}
            page={pagination.page}
            pageSize={pagination.limit}
            pageSizes={[5, 10, 15]}
            size="md"
            totalItems={pagination.total_item}
          />
        </TableContainer>
      ) : (
        <TableLoader {...helpers.tableLoaderProps(headers)} />
      )}
      {/* Invite TAX PROFESSIONALS/FIRMS popup */}
      <InviteFirm open={invitefirm} setOpen={setInviteFirm} />
      {/* Create Update FIRMS popup */}
      <CreateUpdateFirm open={createfirm} setOpen={setCreateFirm} id={selecteditem} />
      {/* View Profile popup */}
      <ViewProfile open={viewprofile} setOpen={setViewProfile} id={selecteditem} />
      {/* Delete Confirmation popup */}
      <DeleteConfirmation
        open={deleteitem}
        setOpen={setDeleteItem}
        module="firm"
        id={selecteditem}
      />
    </>
  );
};

List.defaultProps = {
  collator: new Intl.Collator(),
  hasSelection: false,
  pageSize: 5,
  size: TABLE_SIZE.REGULAR,
  start: 0,
};

export default List;
