import React, { useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  TableContainer,
  Table,
  TableHead,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableSelectRow,
  TableSelectAll,
  TableExpandRow,
  TableExpandedRow,
  TableToolbar,
  TableToolbarContent,
  Pagination,
  Button,
} from '@carbon/react';
import { TrashCan as Delete, Download, Folder, Document, Checkmark } from '@carbon/icons-react';
import { useRowSelection, useSortInfo, useUniqueId } from '../../../_helpers/tables/table_hooks';
import moment from 'moment';
import useToastAlert from '../../../hooks/useToastAlert';
import DeleteConfirmation from '../../common/DeleteConfirmation';
import ChangeStatus from '../../common/ChangeStatus';
import TableLoader from '../../common/TableLoader';
import TableNoData from '../../common/TableNoData';
import { useSelector, useDispatch } from 'react-redux';
import { TABLE_SIZE, TABLE_SORT_DIRECTION } from '../../../_helpers/tables/table_misc';
import { Header as headers } from './Header';
import helpers from '../../../_helpers/common';
import { __trans, getData, makeRequest } from '../../../_helpers';
import checkIcon from './check.png';
import crossIcon from './icon.png';
import { callCommonAction } from '../../../redux/Common/CommonReducer';

const hasSelection = true;

const List = () => {
  const { addError } = useToastAlert();
  const dispatch = useDispatch();
  const [deleteitem, setDeleteItem] = useState(false);

  const { contentLoading, reloaddata } = useSelector((state) => state.common);
  const [deletedocument, setDeleteDocument] = useState(false);
  const [changestatus, setChangeStatus] = useState(false);
  const [itemid, setItemId] = useState('0');
  const [expandedRows, setExpandedRows] = useState([]);
  const [selecteditem, setSelectedItem] = useState('');

  const [selecteditems, setSelectedItems] = useState([]);
  const [rows, setRows] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 15,
    total_pages: 0,
    total_item: 0,
  });
  const [sortInfo, setSortInfo] = useSortInfo({
    columnId: '_id',
    direction: TABLE_SORT_DIRECTION.DESCENDING,
  });
  const [setRowSelection] = useRowSelection(rows, '', setRows);
  const selectedRowsCount = rows.filter(({ selected }) => selected).length;
  const selectedAll = selectedRowsCount > 0 && rows.length === selectedRowsCount;
  const hasBatchActions = hasSelection && selectedRowsCount > 0;
  const elementId = useUniqueId('client_document_list');
  const selectionAllName = !hasSelection
    ? undefined
    : `__custom-data-table_select-all_${elementId}`;
  const { columnId: sortColumnId, direction: sortDirection } = sortInfo;

  const getDocumentList = async () => {
    await getData(
      'firm/request-list',
      { type: 'document', page: pagination.page, limit: pagination.limit },
      'POST',
      addError,
      dispatch
    ).then((res) => {
      if (res.type === 'success') {
        setRows(res.data.docs);
        setPagination({
          ...pagination,
          total_pages: Number(res.data.totalPages),
          total_item: Number(res.data.totalDocs),
        });
      }
    });
  };

  const handleGetData = useCallback(() => {
    getDocumentList();
  }, []);

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  useEffect(() => {
    if (reloaddata) {
      handleGetData();
    }
  }, [reloaddata]);

  useEffect(() => {
    getDocumentList();
  }, [pagination.page, pagination.limit]);

  // Function to handle row expansion
  const handleRowExpansion = (rowId) => {
    const isRowExpanded = expandedRows.includes(rowId);
    if (isRowExpanded) {
      // Remove from expandedRows if already expanded
      setExpandedRows(expandedRows.filter((id) => id !== rowId));
    } else {
      // Add to expandedRows if not expanded
      setExpandedRows([...expandedRows, rowId]);
    }
  };

  /*const handleChangeStatus = () => {
		setChangeStatus(true);
		var selected_items = filteredRows.map((item) => { 
			if(item.selected){
				return item._id;
			} 
		}).filter((item) => {
			if(item){
				return item;
			}
		});
		setSelectedItems(selected_items);
	}*/

  const handleChangeSelection = useCallback(
    (event) => {
      const { currentTarget } = event;
      const row = currentTarget.closest('tr');
      if (row) {
        setRowSelection(row.dataset.rowId, currentTarget.checked);
      }
    },
    [setRowSelection]
  );

  const handleChangeSelectionAll = useCallback(
    (event) => {
      setRowSelection(undefined, event.currentTarget.checked);
    },
    [setRowSelection]
  );

  const handleChangeSort = useCallback(
    (event) => {
      const { currentTarget } = event;
      const { columnId, sortCycle, sortDirection: oldDirection } = currentTarget.dataset;
      setSortInfo({ columnId, sortCycle, oldDirection });
    },
    [setSortInfo]
  );

  /* const handleDeleteRows = useCallback(() => {
		
	}, []); */

  const handlePaginationChange = (e) => {
    //console.log('pagination event', e)
    setPagination({ ...pagination, page: e.page, limit: e.pageSize });
  };

  const processParentTableCell = (columnId, row, index) => {
    if (columnId === 'actions') {
      return <TableCell key={columnId}></TableCell>;
    } else if (columnId === 'expand') {
      return (
        <TableCell>
          <TableExpandRow
            isExpanded={expandedRows.includes(row._id)}
            expandIconDescription={expandedRows.includes(row._id) ? 'Collapse row' : 'Expand row'}
            onExpand={() => handleRowExpansion(row._id)}
          />
        </TableCell>
      );
    } else if (columnId === 'name') {
      return (
        <TableCell key={columnId + index}>
          <Folder className="me-1" />{' '}
          {row['client_id'] ? 'Client ' + row['client_id'].client_id : ''}
        </TableCell>
      );
    } else if (columnId === 'company') {
      var company_name = '';
      if (row['client_id']) {
        if (row['client_id'].client_profile_id) {
          company_name = row['client_id'].client_profile_id.company_name;
        }
      }
      return (
        <TableCell key={columnId + index} title={company_name} style={{ cursor: 'pointer' }}>
          {company_name}
        </TableCell>
      );
    } else if (columnId === 'type') {
      return <TableCell key={columnId + index}>{'Folder'}</TableCell>;
    } else if (columnId === 'size') {
      return <TableCell key={columnId + index}>{'-'}</TableCell>;
    } else if (columnId === 'recived_date') {
      return (
        <TableCell key={columnId + index}>
          {moment(row.created_at).format('MM/DD/YYYY, HH:mmA')}
        </TableCell>
      );
    } else {
      return <TableCell key={columnId + index}>{row[columnId]}</TableCell>;
    }
  };

  const handleDeleteItem = (id) => {
    setSelectedItem(id);
    setDeleteItem(true);
  };

  const handleRecognize = async (id) => {
    var submit_url = 'document/recognize-uploaded-document';
    await makeRequest(submit_url, { id: id }, 'POST', addError)
      .then((res) => {
        if (res.type === 'success') {
          dispatch(callCommonAction({ reloaddata: true }));
        }
      })
      .catch((error) => {});
  };

  const prepareChildTable = (item) => {
    const { file_url } = item;
    const cleanUrl = file_url ? file_url.replace('https://backend.docufly.ai/', '') : '';
    const sanitizedUrl = cleanUrl.replace('http://localhost:5000/', '');
    return (
      <TableRow>
        <TableCell></TableCell>
        <TableCell>
          {item?.document_ai_response >= 0.99 ? (
            <img
              style={{
                width: '16px',
                height: '16px',
                marginRight: '4px',
              }}
              src={checkIcon}
              alt="Checkmark"
            />
          ) : (
            <img
              style={{
                width: '16px',
                height: '16px',
                marginRight: '4px',
              }}
              src={crossIcon}
              alt="crossMark"
            />
          )}
        </TableCell>
        <TableCell title={item.original_file_name} style={{ cursor: 'pointer' }}>
          <Document className="me-1" />
          {item.original_file_name}
        </TableCell>
        <TableCell>{'---'}</TableCell>
        <TableCell>{item.file_type}</TableCell>
        <TableCell>{helpers.formatBytes(item.size)}</TableCell>
        <TableCell>{moment(item.created_at).format('MM/DD/YYYY, HH:mmA')}</TableCell>
        <TableCell>
          <div className="d-flex align-items-center">
            {item?.document_ai_response <= 0.99 && (
              <Button
                size="sm"
                kind="ghost"
                hasIconOnly
                renderIcon={Checkmark}
                onClick={() => handleRecognize(item._id)}
                iconDescription={__trans('Recognize')}
              />
            )}
            <Link
              style={{ textDecoration: 'inherit', color: 'inherit' }}
              to={sanitizedUrl}
              target="_blank"
              download
            >
              <Download />
            </Link>

            {item?.document_ai_response <= 0.99 && (
              <Button
                size="sm"
                kind="ghost"
                renderIcon={Delete}
                onClick={() => handleDeleteItem(item._id)}
                iconDescription={__trans('delete_button')}
                hasIconOnly
              />
            )}
          </div>
          <div></div>
        </TableCell>
      </TableRow>
    );
  };

  /* eslint-disable no-script-url */
  return (
    <>
      {!contentLoading ? (
        <TableContainer title={__trans('uploaded_document_list_title')} description={''}>
          <TableToolbar size="lg">
            <TableToolbarContent className="align-items-center">
              <Button
                size="sm"
                kind="ghost"
                hasIconOnly
                renderIcon={Delete}
                iconDescription={__trans('delete_button')}
                onClick={() => alert('TableToolbarAction - Delete')}
              />
              <Button
                size="sm"
                kind="ghost"
                hasIconOnly
                renderIcon={Download}
                iconDescription={__trans('download_button')}
                onClick={() => alert('TableToolbarAction - Download')}
              />
            </TableToolbarContent>
          </TableToolbar>
          <Table className="expandable-table" size="sm" isSortable useZebraStyles={true}>
            <TableHead>
              <TableRow>
                {hasSelection && (
                  <TableSelectAll
                    id={`${elementId}--select-all`}
                    checked={selectedAll}
                    indeterminate={selectedRowsCount > 0 && !selectedAll}
                    ariaLabel="Select all rows"
                    name={selectionAllName}
                    onSelect={handleChangeSelectionAll}
                  />
                )}
                {headers.map(({ id: columnId, sortCycle, title }) => {
                  const sortDirectionForThisCell =
                    sortCycle &&
                    (columnId === sortColumnId ? sortDirection : TABLE_SORT_DIRECTION.NONE);
                  return (
                    <TableHeader
                      key={columnId}
                      isSortable={Boolean(sortCycle)}
                      isSortHeader={sortCycle && columnId === sortColumnId}
                      sortDirection={sortDirectionForThisCell}
                      data-column-id={columnId}
                      data-sort-cycle={sortCycle}
                      data-sort-direction={sortDirectionForThisCell}
                      onClick={handleChangeSort}
                    >
                      {title}
                    </TableHeader>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                rows.map((row, index) => {
                  const { _id: rowId, selected } = row;
                  const selectionName = !hasSelection
                    ? undefined
                    : `__custom-data-table_${elementId}_${rowId}`;
                  const documents = row.uploaded_documents;
                  return (
                    <React.Fragment>
                      <TableRow
                        key={rowId}
                        isSelected={hasSelection && selected}
                        data-row-id={rowId}
                      >
                        {hasSelection && (
                          <TableSelectRow
                            id={`${elementId}--select-${rowId}`}
                            checked={Boolean(selected)}
                            name={selectionName}
                            ariaLabel="Select row"
                            onSelect={handleChangeSelection}
                          />
                        )}
                        {headers.map(({ id: columnId }) =>
                          processParentTableCell(columnId, row, index)
                        )}
                      </TableRow>
                      {expandedRows.includes(row._id) && (
                        <TableExpandedRow colSpan={headers.length + 1}>
                          <Table size="sm" useZebraStyles={true}>
                            <TableBody>{documents.map((doc) => prepareChildTable(doc))}</TableBody>
                          </Table>
                        </TableExpandedRow>
                      )}
                    </React.Fragment>
                  );
                })
              ) : (
                <TableNoData headers={headers} />
              )}
            </TableBody>
          </Table>
          <Pagination
            backwardText="Previous page"
            forwardText="Next page"
            itemsPerPageText="Items per page:"
            onChange={(e) => handlePaginationChange(e)}
            page={pagination.page}
            pageSize={pagination.limit}
            pageSizes={[5, 10, 15]}
            size="md"
            totalItems={pagination.total_item}
          />
        </TableContainer>
      ) : (
        <TableLoader rows_count={4} colomn_count={5} />
      )}
      {/* Update Status popup */}
      <ChangeStatus
        open={changestatus}
        setOpen={setChangeStatus}
        module="document"
        selectedItems={selecteditems}
      />
      {/* Delete Confirmation popup */}
      <DeleteConfirmation
        open={deleteitem}
        setOpen={setDeleteItem}
        module="uploaded-document"
        id={selecteditem}
      />
    </>
  );
};

List.defaultProps = {
  collator: new Intl.Collator(),
  hasSelection: false,
  pageSize: 5,
  size: TABLE_SIZE.REGULAR,
  start: 0,
};

export default List;
