import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  TextInput,
  Select,
  SelectItem,
  RadioButton,
  RadioButtonGroup,
  CheckboxGroup,
  Loading,
} from 'carbon-components-react';
import { useSelector, useDispatch } from 'react-redux';
import useToastAlert from '../../../../hooks/useToastAlert';
import { __trans, getData } from '../../../../_helpers';

const QuestionAnswer = ({ request, show }) => {
  const { addError } = useToastAlert();
  const { contentLoading } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const [template, setTemplate] = useState({});
  const [formData, setFormData] = useState({});
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const getQuestionAnswer = async () => {
    await getData(
      'template/get-client-template-question-answer/' + request?._id,
      {},
      'POST',
      addError,
      dispatch
    ).then((res) => {
      //console.log('res', res);
      if (res.type === 'success') {
        //if(res.questions[0].question.length > 0){
        setFormData(res.questions[0].question);
        //}
        if (res.answer !== null && res.answer.answers !== undefined) {
          setFormValues(res.answer.answers);
        } else {
          var questions_data = res.questions[0].question;
          var value_object = {};
          for (const item of questions_data) {
            value_object[item._id] = '';
          }
          setFormValues(value_object);
        }
        setTemplate(res.template);
      }
    });
  };

  useEffect(() => {
    if (show === 2) {
      getQuestionAnswer();
    }
  }, [show]);

  /* handle on change input  */
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newEdit = { ...formValues };
    newEdit[name] = value;
    setFormValues(newEdit);

    if (!value || value <= '0') {
      //console.log(value,'value')
      let msg = __trans('error_msg.field_required_error');
      if (value <= '0' && value !== '') {
        msg = `This field is must be greater than 0.`;
      }
      setFormErrors({ ...formErrors, [name]: msg });
    } else {
      const { [name]: _, ...rest } = formErrors;
      setFormErrors(rest);
    }
  };

  const handleRadioChange = (value, name) => {
    let newEdit = { ...formValues };
    newEdit[name] = value;
    setFormValues(newEdit);
  };

  const handleCheckboxChange = (e, item_id) => {
    const { name } = e.target;
    var selectedoption = [];
    if (formValues[item_id] !== '') {
      selectedoption = formValues[item_id].split(',');
    }
    const newArray = selectedoption.filter((item, index) => item !== name);

    if (e.target.checked) {
      newArray.push(name);
    }
    let newEdit = { ...formValues };
    newEdit[item_id] = newArray.toString();
    setFormValues(newEdit);
  };

  const checkCheckboxChecked = (item_id, name) => {
    var selectedoption = [];
    if (formValues[item_id] !== '') {
      selectedoption = formValues[item_id].split(',');
    }
    return selectedoption.includes(name);
  };

  const checkRadioChecked = (item_id, name) => {
    var selected = false;
    if (formValues[item_id] !== '') {
      selected = formValues[item_id] === name ? true : false;
    }
    return selected;
  };

  const renderFormElement = (item, index) => {
    switch (item.type) {
      case 'number':
        return (
          <TextInput
            type={item.type}
            name={item._id}
            id={item._id}
            className="bg-transparent"
            readOnly={true}
            value={formValues[item._id]}
            onChange={handleInputChange}
            labelText=""
            placeholder=""
            invalid={
              formErrors[item._id] !== undefined && formErrors[item._id] !== '' ? true : false
            }
            invalidText={
              formErrors[item._id] !== undefined && formErrors[item._id] !== ''
                ? formErrors[item._id]
                : ''
            }
          />
        );
      case 'text':
        return (
          <TextInput
            type={item.type}
            name={item._id}
            id={item._id}
            className="bg-transparent"
            readOnly={true}
            value={formValues[item._id]}
            onChange={handleInputChange}
            labelText=""
            placeholder=""
            invalid={
              formErrors[item._id] !== undefined && formErrors[item._id] !== '' ? true : false
            }
            invalidText={
              formErrors[item._id] !== undefined && formErrors[item._id] !== ''
                ? formErrors[item._id]
                : ''
            }
          />
        );
      case 'select':
        return (
          <Select
            name={item._id}
            id={item._id}
            labelText=""
            className="bg-transparent"
            readOnly={true}
            value={formValues[item._id]}
            onChange={handleInputChange}
            invalid={
              formErrors[item._id] !== undefined && formErrors[item._id] !== '' ? true : false
            }
            invalidText={
              formErrors[item._id] !== undefined && formErrors[item._id] !== ''
                ? formErrors[item._id]
                : ''
            }
          >
            <SelectItem disabled value="" text="Select" />
            {item.question_options.map((option, optInd) => {
              return <SelectItem key={option._id} value={option._id} text={option.label} />;
            })}
          </Select>
        );
      case 'checkbox':
        return (
          <CheckboxGroup
            name={item._id}
            legendText=""
            disabled={true}
            value={formValues[item._id]}
            invalid={
              formErrors[item._id] !== undefined && formErrors[item._id] !== '' ? true : false
            }
            invalidText={
              formErrors[item._id] !== undefined && formErrors[item._id] !== ''
                ? formErrors[item._id]
                : ''
            }
          >
            {item.question_options.map((option, optInd) => {
              return (
                <Checkbox
                  checked={checkCheckboxChecked(item._id, option._id)}
                  key={option._id}
                  labelText={option.label}
                  name={option._id}
                  onChange={(e) => handleCheckboxChange(e, item._id)}
                  id={option._id}
                />
              );
            })}
          </CheckboxGroup>
        );
      case 'radio':
        return (
          <RadioButtonGroup
            name={item._id}
            legendText=""
            orientation="vertical"
            disabled={true}
            value={formValues[item._id]}
            onChange={(e) => handleRadioChange(e, item._id)}
            invalid={
              formErrors[item._id] !== undefined && formErrors[item._id] !== '' ? true : false
            }
            invalidText={
              formErrors[item._id] !== undefined && formErrors[item._id] !== ''
                ? formErrors[item._id]
                : ''
            }
          >
            {item.question_options.map((option, optInd) => {
              return (
                <RadioButton
                  key={option._id}
                  checked={checkRadioChecked(item._id, option._id)}
                  value={option._id}
                  name="radio-button_demo"
                  id={option._id}
                  labelText={option.label}
                />
              );
            })}
          </RadioButtonGroup>
        );
      default:
        return <p>Default Component</p>;
    }
  };

  return (
    <section className="border p-3">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-9 mx-auto">
            <div className="card mb-3 questioner first">
              <div className="card-body ">
                <p className="mb-3">{template.name}</p>
              </div>
            </div>
            {request?.question_template_id !== null ? (
              <>
                {!contentLoading ? (
                  <>
                    {formData?.length > 0
                      ? formData?.map((item, index) => (
                          <>
                            <div key={item._id} className="card mb-3 questioner">
                              <div className="card-body ">
                                <h4>
                                  {item.name} <span className="text-danger">*</span>
                                </h4>
                                {renderFormElement(item, index)}
                              </div>
                            </div>
                          </>
                        ))
                      : ''}
                  </>
                ) : (
                  <div className="d-flex justify-content-center">
                    <Loading className="text-center" withOverlay={false} />
                  </div>
                )}
              </>
            ) : (
              <div className="text-center my-5">
                <h5>{__trans('no_question_asked')}</h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default QuestionAnswer;
