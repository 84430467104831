import { Form, Stack, ContainedList, ContainedListItem } from '@carbon/react';
import { CheckmarkOutline, MisuseOutline } from '@carbon/icons-react';
import { __trans } from '../../../../_helpers';

const RequestInfo = ({ request, show }) => {
  return (
    <Form aria-label="create/update client form" className="border p-3">
      <Stack>
        <div className="">
          <div className="row mb-2">
            {request?.package_id !== '' ? (
              <div className="col-md-8">
                <ContainedList
                  size="sm"
                  label={__trans('forms.request_package_incude_label')}
                  className="p-3 border bg-body"
                >
                  <ContainedListItem
                    renderIcon={request?.sign_document === 1 ? CheckmarkOutline : MisuseOutline}
                    className={request?.sign_document === 1 ? 'icon-success' : 'icon-danger'}
                  >
                    {__trans('forms.sign_document_label')}
                  </ContainedListItem>
                  <ContainedListItem
                    renderIcon={
                      request?.question_template_id !== '' ? CheckmarkOutline : MisuseOutline
                    }
                    className={
                      request?.question_template_id !== '' ? 'icon-success' : 'icon-danger'
                    }
                  >
                    {__trans('forms.complete_questionare_label')}
                  </ContainedListItem>
                  <ContainedListItem
                    renderIcon={
                      request?.documents_template_id !== '' ? CheckmarkOutline : MisuseOutline
                    }
                    className={
                      request?.documents_template_id !== '' ? 'icon-success' : 'icon-danger'
                    }
                  >
                    {__trans('forms.request_files_label')}
                  </ContainedListItem>
                </ContainedList>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </Stack>
    </Form>
  );
};

export default RequestInfo;
