import React, { useState } from 'react';
import { Modal } from 'carbon-components-react';
import { useDispatch } from 'react-redux';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import useToastAlert from '../../../hooks/useToastAlert';
import { __trans, makeRequest } from '../../../_helpers';

const RestoreConfirmation = ({ open, setOpen, id }) => {
  const { addError } = useToastAlert();
  const dispatch = useDispatch();
  const [submitcontrol, setSubmitControle] = useState({
    status: 'inactive',
    description: __trans('restore_button'),
  });

  const resetStatus = () => {
    setSubmitControle({
      ...submitcontrol,
      status: 'inactive',
      description: __trans('restore_button'),
    });
  };

  const handleRestoreSelected = async (id) => {
    setSubmitControle({ ...submitcontrol, status: 'active' });
    await makeRequest('firm/restore-request/' + id, {}, 'POST', addError)
      .then((res) => {
        setSubmitControle({ ...submitcontrol, status: 'finished' });
        dispatch(callCommonAction({ reloaddata: true }));
        if (res.type === 'success') {
          //setTimeout(() => {
          setOpen(false);
          //}, 2000);
        }
      })
      .catch((error) => {
        setSubmitControle({ ...submitcontrol, status: 'finished' });
      });
  };

  return (
    <Modal
      className="header-border two-buttons"
      size="sm"
      open={open}
      onRequestClose={() => setOpen(false)}
      modalHeading={__trans('common.restore_request_page_heading')}
      modalLabel=""
      preventCloseOnClickOutside={true}
      primaryButtonText={__trans('restore_button')}
      secondaryButtonText={__trans('cancel_button')}
      onRequestSubmit={() => handleRestoreSelected(id)}
      loadingStatus={submitcontrol.status}
      loadingDescription={submitcontrol.description}
      onLoadingSuccess={resetStatus}
    >
      <p>{__trans('common.restore_request_page_info')} </p>
    </Modal>
  );
};

export default RestoreConfirmation;
