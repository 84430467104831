import { TextArea } from 'carbon-components-react';
import { __trans } from '../../../../_helpers';

const ClientFeedback = ({ request, show }) => {
  var message =
    request?.message_from_client !== '' ? request?.message_from_client : 'No Feedback Provided';
  return (
    <>
      <div className="container border p-3">
        <div className="">
          <div className="row">
            <div className="cds--data-table-header">
              <h4 className="cds--data-table-header__title" id="tc-:r1dn:-title">
                {__trans('account_setup_step4_info')}
              </h4>
            </div>
            <div className="col">
              <div className="col-md-8">
                <TextArea
                  className="textarea-white border"
                  readOnly={true}
                  value={message}
                  labelText=""
                  rows={10}
                  id="text-area-1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientFeedback;
