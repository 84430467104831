import { __trans } from '../../../_helpers';

export const Header = [
  {
    id: 'first_name',
    title: __trans('staff_table_header.first_name'),
    sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'last_name',
    title: __trans('staff_table_header.last_name'),
    sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'email',
    title: __trans('staff_table_header.email'),
    sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'phone_no',
    title: __trans('staff_table_header.phone_no'),
    sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'licence_no',
    title: __trans('staff_table_header.licence_no'),
    sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'created_at',
    title: __trans('staff_table_header.created_at'),
    sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'status',
    title: __trans('staff_table_header.status'),
    sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'actions',
    title: __trans('staff_table_header.actions'),
  },
];
