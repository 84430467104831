import DummyImage from '../assets/images/avatar.png';

export function userDetails(key) {
  const authtoken = localStorage.getItem('token');
  if (authtoken) {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      if (key === 'user_image') {
        if (user.user_image !== null) {
          return process.env.REACT_APP_ASSET_ENDPOINT + user.user_image;
        } else {
          return DummyImage;
        }
      } else if (key === 'id') {
        return user._id;
      } else if (key === 'fullname') {
        return user.fullname;
      } else if (key === 'email') {
        return user.email;
      } else if (key === 'country') {
        return user.country;
      } else if (key === 'role') {
        return user.role;
      } else if (key === 'firm_id') {
        return user.firm_id;
      } else if (key === 'client_setup') {
        return user.client_setup_status;
      } else if (key === 'ques_temp_id') {
        return user.question_template_id;
      } else if (key === 'doc_temp_id') {
        return user.document_template_id;
      } else if (key === 'request_id') {
        if (user.has_request === 1) {
          return user.request_id;
        }
      }
    }
  } else {
    return '';
  }
}
