import WebLayout from '../../layout/WebLayout';
const TermAndCondition = () => {
  return (
    <WebLayout>
      <div className="row d-flex align-items-center">
        <div className="col-lg-12 pb-5">
          <h1 className="display-3 mb-5">Sample Terms and Conditions Template</h1>
          <p>
            <strong>Terms and Conditions agreements</strong> serve as a legally binding contract
            between you (the business) who manages a mobile application or a website and the
            customer who uses your app or visits your website.
          </p>
          <p>
            They are otherwise called <strong>Terms of Use</strong> or{' '}
            <strong>Terms of Service</strong> agreements and are shortened as{' '}
            <strong>T&amp;Cs </strong> (or <strong>ToU</strong> and <strong>ToS</strong>,
            respectively).
          </p>
          <p>
            No two businesses are identical, and as such, every business needs a unique T&amp;C
            agreement designed to meet its specific needs. While some clauses are conventional and
            usually seen in basically every Terms and Conditions agreement, you ultimately decide
            what regulations and guidelines your customers must accept.
          </p>
          <p>
            Let's take a look at what clauses should go into most Terms and Conditions agreements,
            as well as how to display and get legal agreement to your T&amp;C.{' '}
          </p>
          <p>
            Although employing a Terms and Conditions agreement is <strong>fully optional</strong>{' '}
            as there are <strong>no laws</strong> that necessitate it, this agreement is{' '}
            <strong>highly recommended</strong> by experts and presents lots of benefits for both
            your company and customers.
          </p>
          <h2>What are the Advantages of Having a Terms and Conditions Agreemt?</h2>
          <p>Some of the advantages to be derived include the following:</p>
          <ul>
            <li>
              <p>
                <strong>Clarity</strong>
              </p>
              <p>
                Terms and Conditions agreements provide clarity about what should occur in any given
                circumstance. They set out the{' '}
                <strong>essential business terms you are offering</strong> to your customers and
                help contractual parties to understand their rights, obligations, functions, and
                accountabilities.
              </p>
            </li>
            <li>
              <p>
                <strong>A Comprehensive system</strong>
              </p>
              <p>
                Your Terms and Conditions agreement produces a well-organized and comprehensive
                system by covering commercial terms such as delivery, price, payment, and other
                areas often overlooked by businesses.
              </p>
              <p>
                They also proceed to limit your liability,{' '}
                <strong>disclaiming your liability for failure or delay</strong> caused by
                inevitable circumstances, therefore protecting your intellectual property rights.
              </p>
            </li>
            <li>
              <p>
                <strong>Conformity with Laws</strong>
              </p>
              <p>
                There may be specific regulations that apply to your industry, and you may refer to
                them in your Terms and Conditions. Having a defined Terms and Conditions agreement
                helps ensure conformity with applicable laws.
              </p>
            </li>
            <li>
              <p>
                <strong>Confidence and Consistency</strong>
              </p>
              <p>
                Having a clear set of Terms and Conditions helps you deliver a consistently
                excellent level of customer service while avoiding any ambiguity.
              </p>
              <p>
                By doing this, customer's expectations are well-managed regarding delivery and
                payment, therefore eliminating disappointments in your provision of services.
              </p>
            </li>
            <li>
              <p>
                <strong>Assurance</strong>
              </p>
              <p>
                If you have a clear set of Terms and Conditions, it is considerably{' '}
                <strong>easier to spot a breach of contract</strong>. Written contracts provide
                assurance and are easier to enforce whenever required.
              </p>
              <p>
                Having a comprehensive set of Terms and Conditions also{' '}
                <strong>helps minimize the probability of getting a legal dispute</strong>. It is
                more prudent to have a Terms and Conditions agreement prepared than be involved in
                prolonged and expensive litigation.
              </p>
            </li>
          </ul>
          <h2>What are Some Clauses I Should Add to my Terms and Conditions Agreement?</h2>
          <p>
            Terms and Conditions are <strong>considerably broad</strong> in the abstract sense.
            However, as businesses differ, they require <strong>some specific clauses</strong> that
            are <strong>exclusive</strong> only to their form of business, depending on:
          </p>
          <ul>
            <li>The size of the contract</li>
            <li>The complexity of the agreement</li>
            <li>The industry</li>
          </ul>
          <p>
            Generally, almost every Terms and Conditions agreement should include the subsequent
            clauses:
          </p>
          <h3>An Intellectual Property clause</h3>
          <p>
            This clause is a provision where you, the business owner or inventor, retains ownership
            of your patents, copyrights, trademarks, trade dresses, or trade secrets.
          </p>
          <p>
            Intellectual property security is <strong>crucial to promoting innovation</strong>.
          </p>
          <p>
            Without the protection of ideas, individuals and businesses would not gain the full
            benefits of their creativity and would concentrate less on research and development.
          </p>
          <p>Here's an example of Intellectual Property Policy for sellers on its platform:</p>
          <p>
            <img
              className="img-fluid"
              src="amazon-intellectual-property-policy-sellers-intro-section.jpg"
              alt="Amazon Intellectual Property Policy for Sellers - Intro section"
            />
          </p>
          <p>
            This excerpt from Amazon sets out what sellers must do, and what they must not do when
            it comes to IP rights. It also adds some definitions and extra information for clarity,
            which is helpful to the readers.
          </p>
          <h3>A Disclaimer and Limitation of Liability clause</h3>
          <p>
            This type of clause is a contractual stipulation or disclaimer for an agreement that
            defines the circumstances under which you (the disclaiming party) could be held
            responsible for damages or loss.
          </p>
          <p>
            It further{' '}
            <strong>
              sets the boundaries of damages that may be claimed in certain situations
            </strong>
            . This clause limits the amount payable as well as the exposure a company faces if a
            lawsuit is filed or another claim is made.
          </p>
          <p>
            If deemed admissible, a <strong>Disclaimer and Limitation of Liability clause</strong>{' '}
            can "cover" the number of possible losses to which a business is exposed.
          </p>
          <p>
            For example, a mobile app user experiences loss because they relied on information
            provided on that app. A <strong>Disclaimer and Limitation of Liability clause</strong>{' '}
            in the app's Terms and Conditions agreement could{' '}
            <strong>restrict the liability of the app owner</strong>, and by extension,{' '}
            <strong>limit the recoverable amount of the user</strong>.
          </p>
          <p>Here's an example of Disclaimer and Limitation of Liability clause:</p>
          <p>
            <img
              className="img-fluid"
              src="coca-cola-terms-use-disclaimer-limitation-liability-clause.jpg"
              alt="Coca-Cola Terms of Use: Disclaimer and Limitation of Liability clause"
            />
          </p>
          <p>
            Here's an additional example of a{' '}
            <strong>Disclaimer and Limitation of Liability clause</strong> embedded in the Netflix
            Terms and Conditions:
          </p>
          <p>
            <img
              className="img-fluid"
              src="netflix-terms-conditions-rights-use-brand-assets-clause-disclaimer-warranty-section-highlighted.jpg"
              alt="Netflix Terms and Conditions: Your Rights to Use Netflix Brand Assets clause with Disclaimer of warranty section highlighted"
            />
          </p>
          <h3>A Termination of Agreement clause</h3>
          <p>
            This clause provides specifications of the conditions under which individuals in a
            contract may <strong>dissolve their legal relationship</strong> and abandon the
            fulfillment of their duties. This covers the implication of termination, such as{' '}
            <strong>payments</strong> and other privileges and responsibilities of the contracting
            parties.
          </p>
          <p>
            According to{' '}
            <strong>
              you and your client may terminate an agreement for a material or a basic breach of the
              agreement
            </strong>
            .
          </p>
          <p>A standard agreement may also be terminated for the following reasons:</p>
          <ul>
            <li>Mutual agreement</li>
            <li>
              The <strong>bankruptcy</strong> of a party
            </li>
            <li>
              A <strong>legal mandate</strong> that forbids the agreement
            </li>
            <li>
              Following its <strong>Terms and Conditions</strong> (i.e., the expiration of the term
              of the agreement, or if defined activities are conducted)
            </li>
          </ul>
          <p>
            Termination of agreement clauses are highly recommended and essential in various
            contexts because they oversee both you and your client's rights and responsibilities if
            the business transaction becomes unsustainable. Ordinarily, meetings and negotiations
            precede termination, and therefore termination is, for the most part, the last resort.
          </p>
          <p>
            In its Terms and Conditions agreement, provides details of its Termination clause which
            includes a 15-day written notice, and conditions under which termination may occur:
          </p>
          <p>
            <img
              className="img-fluid"
              src="starbucks-terms-conditions-termination-clause.jpg"
              alt="Starbucks Terms and Conditions: Termination clause"
            />
          </p>
          <p>
            It further outlines the obligations of the seller once the termination takes effect.
          </p>
          <h3>A Governing Law clause</h3>
          <p>
            This clause is a stipulation usually employed in legal contracts, as it determines which
            regulations and laws will take effect in the case of a legal controversy.
          </p>
          <p>
            A business contract sets the terms under which the contracting individuals will do
            business. The implication and impact of these terms can, however, differ significantly,
            subject to which country's legislation governs them.
          </p>
          <p>
            A governing law clause, therefore, tries to{' '}
            <strong>express your choice for the applicable law</strong>. This clause is usually
            included in standard Terms and Conditions agreements for mobile apps or websites.
          </p>
          <p>
            As an example, prefers the <strong>use of arbitration</strong> to resolve disputes:
          </p>
          <p>
            <img
              className="img-fluid"
              src="target-terms-conditions-intro-section-arbitration-highlighted.jpg"
              alt="Target Terms and Conditions: Intro section with arbitration highlighted"
            />
          </p>
          <p>Its governing law and arbitration rules are further detailed below:</p>
          <p>
            <img
              className="img-fluid"
              src="target-terms-conditions-governing-law-arbitration-rules-clause.jpg"
              alt="Target Terms and Conditions: Governing Law and Arbitration rules clause"
            />
          </p>
          <h3>User Rights, Responsibilities, and Guidelines</h3>
          <p>
            This is an essential clause in any reliable Terms and Conditions agreement as it
            describes the rights and responsibilities of users as well as guidelines for using the
            service in a direct and concise manner.
          </p>
          <p>
            This clause lists rights to be enjoyed by your users as well as responsibilities and{' '}
            <strong>firmly states what is not acceptable</strong> in their use of your service.
          </p>
          <p>
            This can be a part of an extended and thorough list in your{' '}
            <strong>Terms and Conditions agreement</strong> to cover the most volume of negative
            practices.
          </p>
          <p>
            Here, we can see the <strong>Rights and Responsibilities of users</strong> in Terms and
            Conditions of Use:
          </p>
          <p>
            <img
              className="img-fluid"
              src="spotify-terms-use-rights-service-proprietary-sections.jpg"
              alt="Spotify Terms of Use: Your rights to use the Spotify service and Spotify's Proprietary Rights sections"
            />
          </p>
          <p>Spotify also briefly references its User Guidelines three sub-headings below:</p>
          <p>
            <img
              className="img-fluid"
              src="spotify-terms-use-user-guidelines-clause.jpg"
              alt="Spotify Terms of Use: User Guidelines clase"
            />
          </p>
          <h3>A Payments and Prices clause</h3>
          <p>
            This clause is a requisite part of any Terms and Conditions agreement designed for
            websites, mobile apps, or similar platforms that involve{' '}
            <strong>subscriptions or paid services</strong>.
          </p>
          <p>
            Without this clause in place, issues or misunderstandings regarding payments and prices
            are inevitable. A well-drafted Payments and Prices clause informs users explicitly about
            vital information such as:
          </p>

          <ul>
            <li>
              The <strong>acceptable currency</strong> or currencies
            </li>
            <li>The satisfactory method of payment</li>
            <li>The results of not meeting payment obligations</li>
            <li>Potential adjustments in future prices</li>
          </ul>
          <p>
            Here's an example of the payment processing company{' '}
            <a href="https://squareup.com/us/en/legal/general/payment">Square</a> describing its
            payment terms:
          </p>
          <p>
            <img
              className="img-fluid"
              src="square-payment-terms-fees-clause.jpg"
              alt="Square Payment Terms: Our Fees clause"
            />
          </p>
          <p>
            <a href="https://www.hellofresh.com/about/termsandconditions">HelloFresh</a> also
            provides a Payments and Prices clause in its Terms and Conditions:
          </p>
          <p>
            <img
              className="img-fluid"
              src="hellofresh-terms-conditions-payment-pricing-clause.jpg"
              alt="HelloFresh Terms and Conditions: Payment and Pricing clause"
            />
          </p>
          <h3>User Submissions/User Generated Content clause</h3>
          <p>
            Operating a mobile app or website that allows user-generated content necessitates a{' '}
            <strong>User Submissions clause</strong> in your{' '}
            <strong>Terms and Conditions agreement</strong>. These clauses are also known as a User
            Generated Content clause.
          </p>
          <p>
            A well-drafted <strong>User Submissions clause</strong> should emphasize that your
            business doesn't create user-generated content, and all uploaded content belongs to the
            users uploading them or has been approved for use by the original owners.
          </p>
          <p>
            A reliable <strong>User Submissions</strong> clause should also distinguish acceptable
            from non-acceptable user-generated content. For example, you may explain that content
            uploaded by users must not contain offensive or demeaning language.
          </p>
          <p>for example, shows its User Submissions clause in a simplified way as shown below:</p>
          <p>
            <img
              className="img-fluid"
              src="wattpad-terms-service-user-generated-content-clause.jpg"
              alt="Wattpad Terms of Service: User Generated Content clause"
            />
          </p>
          <p> also has a similar User Submissions clause in its Terms of Service agreement:</p>
          <p>
            <img
              className="img-fluid"
              src="youtube-terms-service-uploading-content-clause.jpg"
              alt="YouTube Terms of Service: Uploading Content clause"
            />
          </p>
          <p>
            Now that you have an idea of what clauses to include in your Terms and Conditions
            agreement, let's look at where you display your agreement for maximum effectiveness and
            availability.
          </p>
          <h2>Where Should I Display my Terms and Conditions Agreement?</h2>
          <p>
            A standard Terms and Conditions agreement should be readily available to users and be
            systematically placed at pivotal points where they are most likely required.
          </p>
          <p>
            As such, Terms and Conditions should be found anywhere a relationship is established
            between you and your client or end-user.
          </p>
          <p>
            This serves to remind users about your Terms and Conditions at crucial points in their
            exploration where they may need to reference them.
          </p>
          <p>Generally, most Terms and Conditions are found in the following places:</p>
          <h3>Account Sign-up or Registration Forms</h3>
          <p>
            One of the most common places to reference your Terms and Conditions agreement is the
            account sign-up or registration form webpage of your business.
          </p>
          <p>
            They are logically placed here to introduce new explorers to your Terms and Conditions
            before they become users of your service.
          </p>
          <p>
            Here's how references its Terms of Service agreement for users signing up on its
            platform:
          </p>
          <p>
            <img
              className="img-fluid"
              src="tiktok-sign-up-page-terms-service-link-highlighted.jpg"
              alt="TikTok sign up page with Terms of Service link highlighted"
            />
          </p>
          <h3>Website Footers</h3>
          <p>
            Another common practice adopted by many businesses is the placement of their Terms and
            Conditions agreement in the footer of their website.
          </p>
          <p>Like many others, places its Terms agreement in its website footer:</p>
          <p>
            <img
              className="img-fluid"
              src="uber-website-footer-terms-link-highlighted.jpg"
              alt="Uber website footer with Terms link highlighted"
            />
          </p>
          <h3>Mobile or Desktop In-app Menus</h3>
          <p>
            Terms and Conditions agreements are also linked in mobile or desktop app menus. In its
            mobile app menu, links its Terms of Service agreement as shown below:
          </p>
          <p>
            <img
              className="img-fluid"
              src="grammarly-mobile-app-menu-terms-service-link-highlighted.jpg"
              alt="Grammarly mobile app  menu with Terms of Service link highlighted"
            />
          </p>
          <h3>Email Newsletter Sign-up Forms</h3>
          <p>
            A link to your Terms and Conditions agreement may be provided when offering email
            newsletter sign-ups for your users.
          </p>
          <p>
            Here's an example of linking its Terms of Service agreement on its email newsletter
            sign-up form:
          </p>
          <p>
            <img
              className="img-fluid"
              src="bloomberg-email-sign-up-form-terms-service-link-highlighted.jpg"
              alt="Bloomberg email sign-up form with Terms of Service link highlighted"
            />
          </p>
          <p>
            Now that you know how to draft and display your Terms and Conditions agreement, how can
            you get users to agree to it?
          </p>
          <h2>How Do I Make my Terms and Conditions Agreement Enforceable?</h2>
          <p>
            Preparing a comprehensive Terms and Conditions agreement{' '}
            <strong>does not automatically make it enforceable in legal affairs</strong>.
          </p>
          <p>
            Your <strong>users must consent to the terms provided in your agreement</strong> to make
            your Terms and Conditions an <strong>enforceable contract</strong>. As such, you should
            always <strong>obtain explicit, unexceptionable consent to your agreement</strong>.
          </p>
          <p>
            The best way you can accomplish this is by ensuring your users{' '}
            <strong>tick an unchecked box or click a button</strong> to confirm their consent to
            your Terms and Conditions.
          </p>
          <p>
            For example, provides a box for new users to tick, confirming their consent to the Terms
            and Policies of the business:
          </p>
          <p>
            <img
              className="img-fluid"
              src="vudu-create-account-form-agree-terms-privacy-policy-checkbox-highlighted.jpg"
              alt="Vudu Create Account form with I Agree to Terms and Privacy Policy checkbox highlighted"
            />
          </p>
          <p>
            {' '}
            also ensures that its Terms of Service are enforceable by seeking user consent as shown
            below:
          </p>
          <p>
            <img
              className="img-fluid"
              src="tumblr-account-sign-up-form-terms-service-link-highlighted.jpg"
              alt="Tumblr account sign-up form with Terms of Service link highlighted"
            />
          </p>
          <h2>Summary</h2>
          <p>
            A reliable Terms and Conditions agreement lets your users know{' '}
            <strong>what they can expect</strong> from your business and{' '}
            <strong>what is expected from them</strong>.
          </p>
          <p>
            It formulates a contract between your business and the end-user as well as{' '}
            <strong>helps protect your business from potential legal problems</strong>.
          </p>
          <p>
            <strong>Important points to note</strong> in the preparing a Terms and Conditions
            agreement are as follows:
          </p>
          <ul>
            <li>
              A complete and well-detailed Terms and Conditions agreement{' '}
              <strong>minimizes the risks</strong> of getting legal disputes.
            </li>
            <li>
              The consent of users ensures your Terms and Conditions agreement is{' '}
              <strong>enforceable</strong>.
            </li>
            <li>
              Using simple, plain language in your Terms and Conditions agreement comes with
              benefits.
            </li>
            <li>
              Your Terms and Conditions agreement should be <strong>easy to locate</strong> and{' '}
              <strong>displayed in various areas</strong> of your website or app.
            </li>
            <li>
              Having a solid Terms and Conditions agreement with appropriate consent allows you to{' '}
              <strong>enforce your regulation and guidelines</strong> whenever required.
            </li>
          </ul>
        </div>
      </div>
    </WebLayout>
  );
};

export default TermAndCondition;
