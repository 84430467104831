import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Tag, Button } from 'carbon-components-react';
import { ArrowRight, Calendar } from '@carbon/icons-react';
import moment from 'moment';
import useToastAlert from '../../../hooks/useToastAlert';
import { __trans, makeRequest, userDetails } from '../../../_helpers';

const ClientWelcome = () => {
  const navigate = useNavigate();
  const { addError } = useToastAlert();
  const [firmadmindata, setFirmAdminData] = useState({});
  const [requestdata, setRequestData] = useState({});

  const getRequestData = async () => {
    let request_id = userDetails('request_id');
    //console.log('request_id', request_id);
    if (request_id) {
      await makeRequest('firm/get-client-request/' + request_id, {}, 'POST', addError).then(
        (res) => {
          if (res.type === 'success') {
            setRequestData(res.data);
          }
        }
      );
    }
  };

  const getFirmInfo = async () => {
    let firm_id = userDetails('firm_id');
    await makeRequest('users/get-firm-data/' + firm_id, {}, 'POST', addError).then((res) => {
      //console.log('res', res);
      if (res.type === 'success') {
        setFirmAdminData(res.firm_admin);
      }
    });
  };

  useEffect(() => {
    getRequestData();
    getFirmInfo();
  }, []);

  const checkGetDueDays = () => {
    if (requestdata.expire_at !== undefined && requestdata.expire_at !== null) {
      var given = moment(requestdata.expire_at);
      var current = moment().startOf('day');
      //Difference in number of days
      return Math.round(moment.duration(given.diff(current)).asDays());
    }
    return '';
  };

  const checkGetDueDate = () => {
    if (requestdata.expire_at !== undefined && requestdata.expire_at !== null) {
      return moment(requestdata.expire_at).format('MMMM DD, YYYY');
    }
    return '';
  };

  return (
    <>
      <div className="row">
        <div className="col d-flex align-items-center client-setup justify-content-center p-5">
          <div className="w-100" style={{ maxWidth: '550px' }}>
            <img
              className="mb-5 dark-bg-logo"
              src="/images/logo.png"
              alt=""
              title=""
              width={'200px'}
            />
            <h1 className="mb-3">{__trans('account_setup_welcome_text')}</h1>
            <p className="text mb-5">
              {__trans('account_setup_welcome_info', { due_date: checkGetDueDate() })}
            </p>
            <div className="row gap-5 justify-content-center">
              <div className="col client-setup-steps text-center justify-content-between">
                <div className="small">{__trans('account_setup_step1_text')}</div>
                <div className="">
                  <img src="/images/pen-icon.svg" alt="" title="" width={'50px'} height={'50px'} />
                </div>
                <p className="step-name">{__trans('account_setup_step1_info')}</p>
              </div>
              <div className="col client-setup-steps text-center justify-content-between">
                <div className="small">{__trans('account_setup_step2_text')}</div>
                <div className="">
                  <img src="/images/task-icon.png" alt="" title="" />
                </div>
                <p className="step-name">{__trans('account_setup_step2_info')}</p>
              </div>
              <div className="col client-setup-steps text-center justify-content-between">
                <div className="small">{__trans('account_setup_step3_text')}</div>
                <div className="">
                  <img
                    src="/images/cloud-icon.svg"
                    alt=""
                    title=""
                    width={'50px'}
                    height={'50px'}
                  />
                </div>
                <p className="step-name">{__trans('account_setup_step3_info')}</p>
              </div>
            </div>
            <div className="mt-5">
              <Button
                className="btn-primary"
                renderIcon={ArrowRight}
                onClick={() => navigate('/client-account-setup')}
              >
                <span className="pe-3">{__trans('get_started_button')}</span>
              </Button>
            </div>
          </div>
        </div>
        <div className="col d-flex align-items-center msg-from-firm-sec justify-content-center p-5">
          <div className="w-100" style={{ maxWidth: '550px' }}>
            <div className="row align-items-center mb-3 ">
              <div className="col">
                <strong>{__trans('account_setup_firm_message')}</strong>
              </div>
              {requestdata.expire_at !== undefined && requestdata.expire_at !== null ? (
                <div className="col text-end">
                  <Tag className="" type="cyan" renderIcon={Calendar}>
                    {__trans('account_setup_due_days', { due_days: checkGetDueDays() })}
                  </Tag>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="row">
              <div className="col">
                <div className="firm-msg">
                  <p className="mb-3">
                    {__trans('account_setup_firm_gretting')} {userDetails('fullname')},
                  </p>
                  <p>{requestdata ? requestdata.message_to_client : ''}</p>
                  <br />
                  <p>
                    Thanks,
                    <br />
                    {firmadmindata ? firmadmindata.fullname : ''}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row px-3 py-2 frontend-footer">
        <div className="col text-end">
          <span className="me-3">Copyright © 2024</span>{' '}
          <Link to="/term-and-conditions" target={'_blank'}>
            {__trans('terms_of_use_link')}
          </Link>{' '}
          -{' '}
          <Link to="/privacy-policy" target={'_blank'}>
            {__trans('privacy_policy_link')}
          </Link>
        </div>
      </div>
    </>
  );
};

export default ClientWelcome;
