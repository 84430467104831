import React, { useState } from 'react';
import { Modal } from 'carbon-components-react';
import { useDispatch } from 'react-redux';
import { callCommonAction } from '../../redux/Common/CommonReducer';
import useToastAlert from '../../hooks/useToastAlert';
import { __trans, makeRequest } from '../../_helpers';

const DeleteConfirmation = ({ open, setOpen, module, id }) => {
  const { addError } = useToastAlert();
  const dispatch = useDispatch();
  const [submitcontrol, setSubmitControle] = useState({
    status: 'inactive',
    description: __trans('deleting_button'),
  });

  const resetStatus = () => {
    setSubmitControle({
      ...submitcontrol,
      status: 'inactive',
      description: __trans('deleting_button'),
    });
  };

  const handleDeleteSelected = async (id) => {
    setSubmitControle({ ...submitcontrol, status: 'active' });
    var end_url = '';
    if (module === 'question') {
      end_url = 'question/delete';
    } else if (module === 'contact-us') {
      end_url = 'contact-us/delete';
    } else if (module === 'template') {
      end_url = 'template/delete';
    } else if (module === 'firm') {
      end_url = 'users/delete';
    } else if (module === 'staff') {
      end_url = 'users/staff-delete';
    } else if (module === 'client') {
      end_url = 'users/client-delete';
    } else if (module === 'document') {
      end_url = 'document/delete';
    } else if (module === 'uploaded-document') {
      end_url = 'document/delete-uploaded-document';
    } else if (module === 'staff-log') {
      end_url = 'logs/delete-staff-log';
    } else if (module === 'package') {
      end_url = 'request-package/delete';
    } else if (module === 'contract_file') {
      end_url = 'firm/delete-client-contract-file';
    } else if (module === 'client-request') {
      end_url = 'firm/delete-client-request';
    }
    //console.log('end_url', end_url);
    await makeRequest(end_url, { item_id: id }, 'POST', addError)
      .then((res) => {
        setSubmitControle({ ...submitcontrol, status: 'finished' });
        dispatch(callCommonAction({ reloaddata: true }));
        if (res.type === 'success') {
          //setTimeout(() => {
          setOpen(false);
          //}, 2000);
        }
      })
      .catch((error) => {
        setSubmitControle({ ...submitcontrol, status: 'finished' });
      });
  };

  return (
    <Modal
      className="header-border two-buttons"
      size="sm"
      open={open}
      onRequestClose={() => setOpen(false)}
      modalHeading={__trans('common.single_delete_page_heading')}
      modalLabel=""
      preventCloseOnClickOutside={true}
      primaryButtonText={__trans('delete_confirmation_button')}
      secondaryButtonText={__trans('cancel_delete_confirmation_button')}
      onRequestSubmit={() => handleDeleteSelected(id)}
      loadingStatus={submitcontrol.status}
      loadingDescription={submitcontrol.description}
      onLoadingSuccess={resetStatus}
    >
      <p>{__trans('common.single_delete_page_info')} </p>
    </Modal>
  );
};

export default DeleteConfirmation;
