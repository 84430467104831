import { __trans } from '../../../_helpers';

export const Header = [
  {
    id: 'client_id',
    title: __trans('archive_client_table_header.client_id'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'first_name',
    title: __trans('archive_client_table_header.first_name'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'last_name',
    title: __trans('archive_client_table_header.last_name'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'company',
    title: __trans('archive_client_table_header.company'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'client_status',
    title: __trans('archive_client_table_header.status'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'invitation_date',
    title: __trans('archive_client_table_header.delivered_date'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'archive_date',
    title: __trans('archive_client_table_header.archive_date'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'notes',
    title: __trans('archive_client_table_header.notes'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'actions',
    title: __trans('archive_client_table_header.actions'),
  },
];
