import React, { useState, useEffect, useRef } from 'react';
import { Form, TextInput, Button, InlineLoading } from '@carbon/react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import AuthLayout from '../../layout/AuthLayout';
import useToastAlert from '../../../hooks/useToastAlert';
import helpers from '../../../_helpers/common';
import { __trans, makeRequest } from '../../../_helpers';

const ResetPassword = () => {
  const { addError } = useToastAlert();
  // Get tparam from the URL.
  const navigate = useNavigate();
  const token = useParams();
  const { loading } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    password: '',
    password_error: '',
    confirm_password: '',
    confirm_password_error: '',
  });
  const hasCheckedToken = useRef(false);

  useEffect(() => {
    if (!hasCheckedToken.current) {
      checkVerifyToken();
      hasCheckedToken.current = true;
    }
  }, []);

  const checkVerifyToken = async () => {
    await makeRequest('users/check-token/' + token.token, {}, 'GET', addError)
      .then((res) => {
        if (res.type !== 'success') {
          navigate('/forgot-password');
        }
      })
      .catch((error) => {});
  };

  const valueChanged = (e) => {
    const { name, value } = e.target;
    let newEdit = { ...form };
    newEdit[name] = value;
    newEdit[name + '_error'] = '';
    setForm(newEdit);
  };

  const validateForm = () => {
    let newEdit = { ...form };
    let validateData = true;

    if (helpers.isEmpty(form.password)) {
      newEdit.password_error = __trans('error_msg.password_empty');
      validateData = false;
    } else {
      newEdit.password_error = '';
      validateData = true;
      let msg = helpers.isValidFormatForPassword(form.password);
      if (msg !== '') {
        newEdit.password_error = msg;
        validateData = false;
      }
    }

    if (helpers.isEmpty(form.confirm_password)) {
      newEdit.confirm_password_error = __trans('error_msg.password_empty');
      validateData = false;
    } else {
      newEdit.confirm_password_error = '';
      validateData = true;
      let msg = helpers.isValidFormatForPassword(form.confirm_password);
      if (msg !== '') {
        newEdit.confirm_password_error = msg;
        validateData = false;
      } else {
        if (form.confirm_password !== form.password) {
          newEdit.confirm_password_error = __trans('error_msg.confirm_pass_match_paas');
          validateData = false;
        }
      }
    }
    setForm(newEdit);
    return validateData;
  };

  const handleSubmit = (e) => {
    //Before submit validate form
    if (validateForm()) {
      submitForm({
        password: form.password,
        token: token.token,
        password_confirmation: form.confirm_password,
      });
    }
  };

  const submitForm = async (formData) => {
    dispatch(callCommonAction({ loading: true }));
    await makeRequest('users/reset-password', formData, 'POST', addError)
      .then((res) => {
        dispatch(callCommonAction({ loading: false }));
        if (res.type === 'success') {
          navigate('/login');
        }
      })
      .catch((error) => {
        dispatch(callCommonAction({ loading: false }));
      });
  };

  return (
    <AuthLayout>
      <Form className="signup-form mb-3">
        {/*<div className="form-welcome-heading">
						<h2 className="mb-4">{__trans('reset_page_heading')}</h2>
				</div>*/}
        <div className="form-heading mb-5 pt-3">
          <h3 className="mb-3">{__trans('reset_page_title')}</h3>
          <p>{__trans('reset_text')} </p>
        </div>
        <div className="mb-4">
          <TextInput
            type="password"
            name="password"
            id="password"
            onChange={valueChanged}
            labelText={__trans('forms.password_label')}
            placeholder={__trans('forms.password_placeholder')}
            invalid={form.password_error !== '' ? true : false}
            invalidText={form.password_error}
          />
        </div>
        <div className="mb-4">
          <TextInput
            type="password"
            name="confirm_password"
            id="confirm_password"
            onChange={valueChanged}
            labelText={__trans('forms.confirm_password_label')}
            placeholder={__trans('forms.confirm_password_placeholder')}
            invalid={form.confirm_password_error !== '' ? true : false}
            invalidText={form.confirm_password_error}
          />
        </div>
        <div className="mt-4 mb-4">
          {!loading ? (
            <Button className="btn btn-primary" onClick={handleSubmit} size="md">
              {__trans('forms.reset_submit_button')}
            </Button>
          ) : (
            <Button kind="secondary" size="md" as="div" role="button">
              <InlineLoading
                className="text-info"
                style={{ marginLeft: '1rem' }}
                description="Wait..."
                status="active"
                aria-live="off"
              />
            </Button>
          )}
        </div>
      </Form>
    </AuthLayout>
  );
};

export default ResetPassword;
